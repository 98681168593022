<template>
    <div id="loginAdmin">
        <b-container fluid v-cloak>
            <b-row>
                <b-col
                    xs="8"
                    offset-xs="2"
                    md="6"
                    offset-md="3"
                    lg="4"
                    offset-lg="4"
                >
                    <b-card id="loginPanel" no-body>
                        <center id="header">
                            <img src='../assets/img/icon-accuenergy.png'>
                            <h3>Device Template</h3>
                        </center>

                        <div id="loginForm">
                            <b-form-group
                                id="userNameGroup"
                                label="Username: "
                                label-for="userNameInput"
                                description=""
                                label-cols-sm="3"
                            >
                                <b-form-input
                                    id="userNameInput"
                                    v-model="user.username"
                                    type="text"
                                    placeholder=""
                                    required 
                                />
                            </b-form-group>

                            <b-form-group
                                id="passwordGroup"
                                label="Password: "
                                label-for="passwordInput"
                                @keyup.enter.native="login"
                                description=""
                                label-cols-sm="3"
                            >
                                <b-form-input
                                    id="passwordInput"
                                    v-model="user.password"
                                    type="password"
                                    placeholder=""
                                    required
                                />
                            </b-form-group>

                            <b-btn
                                id=""
                                size="lg"
                                variant="primary"
                                @click="login"
                            >
                                Sign in
                            </b-btn>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
var axios = require('axios')

export default
{
    name: 'LoginAdmin',
    data()
    {
        return {
            user:
            {
                username: '',
                password: ''
            },
            isLoggingIn: false,
        }
    },
    methods:
    {
        login: function()
        {
            if (this.user.password.length < 6 || this.user.password.length > 12) {
                this.notify("Passwords must be between 6 and 12 characters", "danger");
                return
            }

            this.isLoggingIn = true
            setTimeout(() =>
            {
                axios.post('/api/login/', this.user)
                    .then((response) =>
                    {
                        sessionStorage.setItem('token', response.data.token)
                        sessionStorage.setItem('user', this.user.username)
                        // add token to headers of axios requests
                        axios.defaults.headers.common['Authorization'] = 'token ' + response.data.token
                        this.$router.push(
                        {
                            path: '/home'
                        })
                    })
                    .catch((error) =>
                    {
                        var message = 'Connection failed'
                        if (error.response.status == 401) {
                            message = 'Incorrect password or username'
                        }

                        this.notify(message, "danger");
                    });
            }, 300)
        }
    },

    mounted: function() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#loginPanel {
    width: 100%;
    margin-top: 20%;
    min-width: 400px;
}

#header {
    margin: 2rem;
}

#loginForm {
    margin: 1rem;
}
</style>