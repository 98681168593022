import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueBlu from 'vue-blu'
import TreeView from 'vue-json-tree-view'
import VueTheMask from 'vue-the-mask'
import 'vue-blu/dist/css/vue-blu.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import blockLabelMixin from './generics/block_label.vue';
// import 'vue-multiselect/dist/vue-multiselect.min.css'
const uuidv1 = require('uuid/v1')
const converter = require('hex2dec');
const axios = require('axios');

Vue.use(TreeView)
Vue.use(VueBlu)
Vue.use(BootstrapVue)
Vue.use(uuidv1)
Vue.use(VueTheMask)
Vue.config.productionTip = false

Vue.mixin({
    methods: {
        request(method, url, data) {
            return new Promise(resolve => {
                axios({
                  method: method,
                  url: url,
                  headers: {'Authorization': 'token ' + sessionStorage.getItem('token')},
                  data: data
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.notify(error.response.data.message.replace('\n', '<br>'), "danger", 100000);
                });
            })
        },

        generateUUID() {
            return uuidv1();
        },

        generateBlockLabel(start, count, index) {
            var hexBlockStartAddress = converter.decToHex(String(start))
            // bug of converter: return null for decimal 0
            if (hexBlockStartAddress === null) {
                hexBlockStartAddress = '0x0'
            }

            var decimalEndAddress = Number(start) + Number(count) -1;
            var hexBlockEndAddress = converter.decToHex(String(decimalEndAddress))

            // bug of converter: return null for decimal 0
            if (hexBlockEndAddress === null) {
                hexBlockEndAddress = '0x0'
            }
            
            // format to 0xFFFFFF
            hexBlockStartAddress = '0x' + hexBlockStartAddress.slice(2).toLocaleUpperCase()
            hexBlockEndAddress = '0x' + hexBlockEndAddress.slice(2).toLocaleUpperCase()

            var blockLabel = "Block " + index + " : " + hexBlockStartAddress + " - " + hexBlockEndAddress + " : " + count
            return blockLabel
        },

        // '0xFFFFFF', hex value is 'FFFFFF' without '0x'
        hexToDec(hex) {
            if (hex === 0) {
                return 0;
            }

            return Number(converter.hexToDec("0x" + hex));
        },

        notify(content, type, duration, placement, closable) {
            this.$notify.open({
                content: content,
                type: type || "danger",
                placement: placement || 'top-center',
                duration: duration || 5000,
                closable: closable || true,
            })
        }
    }
});

new Vue(
{
    router,
    render: h => h(App)
}).$mount('#app')