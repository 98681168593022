<template>
    <span id="deleteBlockModal">
        <b-modal
            ref="deleteModal"
            title="Are you sure to delete this block?"
            @ok="deleteBlock"
            centered  
        >
            All associated points will be discarded
        </b-modal>

        <b-button
            size="sm"
            variant="danger"
            @click.stop="showModal"
        >
            Delete
        </b-button>
    </span>
</template>

<script>
export default {
    name: 'DeleteBlockModal',
    data() {
        return {}
    },
    props: ['blockIndex'],
    methods: {
        showModal() {
            this.$refs.deleteModal.show()
        },

        deleteBlock() {
            // return this.$parent.$parent.$parent.$parent.deleteBlock(blockIndex)
            this.$emit('delete-block', this.blockIndex)
        }
            
    },
    mounted() {}
}
</script>

<style>
#deleteBlockModal {
    display: inline-block;
    margin-left: 2px;
}

</style>