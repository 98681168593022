<template>
    <div>
        <span class="icon" v-if="!isReady">
           <i class="fa fa-spinner fa-spin" style="font-size:48px" ></i>
        </span>

        <b-container v-if="isReady">
            <b-table
                striped
                hover
                stacked="md"
                :items="records"
                :fields="fields"
            >
                <template slot="created_at" slot-scope="data">
                  {{formatDate(data.value.created_at)}}
                </template>
            </b-table>

            <b-pagination
                v-if="totalLogs > pageSize"
                size="sm"
                :total-rows="totalLogs"
                v-model="currentPage"
                :per-page="pageSize"
                @change="loadData">
            </b-pagination>
        </b-container>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'Devices',
    props: {},
    components: {
    },
    data() {
        return {
            isReady: false,
            fields: {
                log_type: {
                  label: 'Type',
                  sortable: true
                },
                created_at: {
                  label: 'Time Created',
                  sortable: true
                },
                message: {
                  label: 'Message',
                  sortable: false
                },
            },

            records: [],

            totalLogs: 0,
            pageSize: 50,
            currentPage: 1,
        }
    },
    methods: {
        formatDate(date) {
            const theDate = moment(date);
            return theDate.format('MMMM Do, YYYY') + " " + theDate.format('HH:mm');
        },

        loadData(page) {
            this.request('get', 'api/logs/?page=' + page)
            .then(response => {
                this.records = response.data.results || [];
            })
        },

        getLogList() {
            this.isReady = false

            this.request('get', 'api/logs/?page=1')
            .then((response)=>{
                const table = response.data;
                const records = table.results;

                this.totalLogs = table.count;

                records.length > 0 ? this.records = records : this.records = [];

                this.isReady = true;
            })
        }
    },

    mounted: function() {
        this.getLogList()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>