<template>
    <div >
        <b-card id="blockModule" title="Block" align="left">
            <hr>
            <b-form @submit.prevent="onSaveBlock">
                <b-form-group
                    id="hexBlockStartGroup"
                    label="Start:"
                    label-for="hexBlockStart"
                    description="Address of beginning register. Type is hexadecimal number"
                    label-cols-sm="3"
                >
                    <div id="hexBlockStart">
                        <label>0x</label>
                        <the-mask
                            class="hexInput"
                            v-model="startHex"
                            mask="FFFFFF"
                            :tokens="hexToken"
                        />
                    </div>
                </b-form-group>

                <b-form-group
                    id="countGroup"
                    label="Count:"
                    label-for="countInput"
                    description="Number of registers. Type is positive decimal number"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="countInput"
                        number
                        v-model="count"
                        :state="count>0"
                        placeholder="Enter number of registers"
                        required
                    />
                </b-form-group>

                <b-form-group
                    id="functionGroup"
                    label="Function:"
                    label-for="functionSelect"
                    label-cols-sm="3"
                    description="Function used to create blocks"
                >
                    <b-form-select
                        id="functionSelect"
                        v-model="func"
                        :options="blockFunctions"
                        required
                    />
                </b-form-group>
                
                <div class="inner-button-container">
                    <b-button
                        class="button"
                        type="submit"
                        variant="primary"
                    >
                        Save block
                    </b-button>
                </div>
            </b-form>
        </b-card>
    </div>
</template>

<script>
import { blockFunctions } from '../fixtures/paramOptions';
import { hexToken } from '../fixtures/constant';

export default {
    name: 'BlockModule',
    data() {
        return {
            hexToken,
            blockFunctions,

            startHex: '',
            count: null,
            func: 'READ_HOLDING_REGISTERS', 
        }
    },
    props: ['blockOptions'],
    components: {},
    computed: {},
    watch: {
        'func': function(val) {
            if (val === 'READ_COILS' || val === 'READ_DISCRETE_INPUTS') {
                this.$notify.info({
                    content: 'For all new points in this block, data format would be "UINT16" and byte order would be "NORMAL"',
                    placement: 'top-center',
                    duration: 5000,
                    closable: true,
                })
            }
        }
    },
    methods: {
        onSaveBlock(evt) {
            evt.preventDefault();
            var message = ''
            var messageType = 'danger'

            if (this.startHex === '') {
                this.notify('Please specify the start address', 'warning');
                return
            }

            const decimalStart = this.hexToDec(this.startHex);
            const blockLabel = this.generateBlockLabel(decimalStart, this.count, this.blockOptions.length)

            var isExisting = false
            var blockLabelWithoutIndex = blockLabel.slice(blockLabel.indexOf(':') + 1)

            this.blockOptions.forEach((block) => {
                var firstColonIndex = block.indexOf(':')
                // remove unnessary information "block xx :"
                block = block.slice(firstColonIndex + 1)
                if (block.indexOf(blockLabelWithoutIndex) !== -1) {
                    isExisting = true
                }
            })

            if (!isExisting) {
                const blockForm = {
                    id: this.generateUUID(),
                    start: decimalStart,
                    startHex: "0x" + this.startHex,
                    count: this.count,
                    function: this.func,
                    points: []
                }

                this.$emit('save', blockForm, blockLabel)

                message = 'Block saved'
                messageType = 'success'
                this.resetBlock()
            } else {
                message = 'Block exists'
            }

            this.notify(message, messageType);
        },

        resetBlock() {
            this.startHex = ''
            this.count = null
            this.func = 'READ_HOLDING_REGISTERS'
        }
    },
    mounted: function() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

#blockModule {
    width: 100%;
    height: 100%;
    min-height: 250px;
}

.form-group {
    text-align: left !important;
}

.inner-button-container{
    position: absolute;
    bottom: 2px;
    right: 0;
    left: 0;
    text-align: center;
}

.button {
    margin: auto;
}

.hexInput {
    display: inline-block;
    width: calc(100% - 14.8px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>