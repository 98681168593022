<template>
    <div id="saveModule">
        <br>
        <b-card style="width: 100%" title="Save" align="left">
            <hr>
            <div style="text-align: left">
                <p> Configuration completed? </p>
                <p> Proceed to save device only if model name and version number have been defined </p>
                <p> If you leave or refresh the page without saving it to database, all locally saved configurations will be discarded</p>
            </div>
            <br>
            <div class="inner-button-container">
                <b-button
                    class="button"
                    type="button"
                    variant="primary"
                    @click.prevent="saveForm"
                >
                    Save device to database
                </b-button>
            </div>
        </b-card>
    </div>
</template>
<script>
export default {
    name: 'SaveModule',
    data() {
        return {}
    },
    props: [],
    components: {},
    computed: {},
    watch: {},
    methods: {
        saveForm() {
            this.$emit('save');
        }
    },
    mounted: function() {}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.inner-button-container{
    position: absolute;
    bottom: 2px;
    right: 0;
    left: 0;
    text-align: center;
}

.button {
    margin: auto;
}

</style>