<template>
    <b-navbar toggleable="md" type="light" variant="light" v-if="$route.path !=='/login'">
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
        <b-navbar-brand href="#">
            <router-link to="/home">
                <img id="logo" src="../../assets/img/logo.png" class="d-inline-block align-top" alt="BV"> Device Template
            </router-link>
        </b-navbar-brand>
        <b-collapse is-nav id="nav_collapse">
            <b-navbar-nav>
                <b-nav-item to="/home">
                    Home
                </b-nav-item>
                <!-- Navbar dropdowns -->
                <b-nav-item-dropdown text="Modbus Template" right>
                    <b-dropdown-item to="/templates?modbus">
                        List Overview
                    </b-dropdown-item>
                    <b-dropdown-item to="/upload?modbus&csv">
                        <a v-b-popover.hover.bottom="'Create new model from a CSV file'" >
                            Upload CSV
                        </a>
                    </b-dropdown-item>
                    <b-dropdown-item to="/upload?modbus&mbt">
                        <a v-b-popover.hover.bottom="'Create new model from a MBT file'" >
                            Upload MBT
                        </a>
                    </b-dropdown-item>
                    <b-dropdown-item
                        to="/devices/new"
                        v-if="!$route.path.includes('devices')"
                    >
                        <a v-b-popover.hover.bottom="'Create new model manually'">
                            Add Device
                        </a>
                    </b-dropdown-item>
                    <b-dropdown-item
                        to="/devices/new?typical"
                        v-if="!$route.path.includes('devices')"
                    >
                        <a v-b-popover.hover.bottom="'Create new typical energy meter manually'">
                            Add Typical Energy Meter
                        </a>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                
                <!-- Navbar dropdowns -->
                <b-nav-item-dropdown text="BACnet Template" right>
                    <b-dropdown-item to="/templates?bacnet">
                        List Overview
                    </b-dropdown-item>
                    <b-dropdown-item to="/upload?bacnet&epics">
                        <a v-b-popover.hover.bottom="'Create new model from a EPIC file'" >
                            Upload EPICS
                        </a>
                    </b-dropdown-item>
                    <b-dropdown-item to="/upload?bacnet&mbt">
                        <a v-b-popover.hover.bottom="'Create new model from a MBT file'" >
                            Upload MBT
                        </a>
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <!-- <b-nav-item to="/devices">
                    Home
                </b-nav-item>
                <b-nav-item to="/upload?csv">
                    <a v-b-popover.hover.bottom="'Create new model from a CSV file'" >
                        Upload CSV
                    </a>
                </b-nav-item>
                <b-nav-item to="/upload?mbt">
                    <a v-b-popover.hover.bottom="'Create new model from a MBT file'" >
                        Upload MBT
                    </a>
                </b-nav-item>
                <b-nav-item to="/devices/new" v-if="$route.path==='/devices' || $route.path.includes('/upload')">
                    <a v-b-popover.hover.bottom="'Create new model manually'" >
                        Add Device
                    </a>
                </b-nav-item>
                <b-nav-item to="/devices/new?typical" v-if="$route.path==='/devices' || $route.path.includes('/upload')">
                    <a v-b-popover.hover.bottom="'Create new typical energy meter manually'" >
                        Add Typical Energy Meter
                    </a>
                </b-nav-item> -->
            </b-navbar-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item to="/logs">
                    Logs
                </b-nav-item>
                <b-nav-item to="/login" @click="logout">
                    Log out
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>
<script>

export default {
    name: 'appHeader',
    data() {
        return {}
    },
    methods: {
        logout() {
            this.request('post', '/api/logout/', {'user': sessionStorage.user});
            
            sessionStorage.clear();
        }
    },
    mounted() {}
}
</script>

<style scoped>

/*.navbar-dark .navbar-brand {
    color: #333 !important;
}*/
</style>