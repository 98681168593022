<template>
    <div>
        <b-card
            title="Modbus Templates"
            class="card"
        >
            <b-card-text>
                List of all Modbus templates. Support to create, delete and download encrypted templates
            </b-card-text>

            <b-button to="/templates?modbus" variant="primary">Go to Modbus Templates</b-button>
        </b-card>

        <b-card
            title="BACnet Templates"
            class="card"
        >
            <b-card-text>
                List of all BACnet templates. Support to create, delete and download encrypted templates
            </b-card-text>

            <b-button to="/templates?bacnet" variant="primary">Go to BACnet Templates</b-button>
        </b-card>
    </div>
</template>

<script>
export default
{
    name: 'homepage',
    data() {
        return {
            
        }
    },
    methods: {
    },

    mounted: function() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
    width: 50%;
    min-width: 500px;
    /*float: left;*/
    margin: 20px auto;
    padding: 50px;
}
</style>