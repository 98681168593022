export const pointTemplate = {
    "uuid": "",
    "label": "",
    "objectId": 1,
    "objectType": 0,
    "postLabel": "",
    "units": "",
    "userScaleEnabled": null,
    "canEditName": null,
    "slope": null,
    "invalidValue": null,
    "slopeEnabled": false,
    "invalidValueEnabled": null,
    "cloudEnabled": false,
    "offset": null,
    "canEditUnits": null,
    "offsetEnabled": null,
};

export const objectTypeOptions = {
    "ANALOG-INPUT": 0,
    "ANALOG-OUTPUT": 1,
    "ANALOG-VALUE": 2,
    "BINARY-INPUT": 3,
    "BINARY-OUTPUT": 4,
    "BINARY-VALUE": 5,
    "CALENDAR": 6,
    "COMMAND": 7,
    "DEVICE": 8,
    "EVENT-ENROLLMENT": 9,
    "FILE": 10,
    "GROUP": 11,
    "LOOP": 12,
    "MULTI-STATE-INPUT": 13,
    "MULTI-STATE-OUTPUT": 14,
    "NOTIFICATION-CLASS": 15,
    "PROGRAM": 16,
    "SCHEDULE": 17,
    "AVERAGING": 18,
    "MULTI-STATE-VALUE": 19,
    "TRENDLOG": 20,
    "LIFE-SAFETY-POINT": 21,
    "LIFE-SAFETY-ZONE": 22,
    "ACCUMULATOR": 23,
    "PULSE-CONVERTER": 24,
    "EVENT-LOG": 25,
    "GLOBAL-GROUP": 26,
    "TREND-LOG-MULTIPLE": 27,
    "LOAD-CONTROL": 28,
    "STRUCTURED-VIEW": 29,
    "ACCESS-DOOR": 30,
    "TIMER": 31,
    /* Note: 31 was lighting output, but BACnet editor changed it... */
    "ACCESS-CREDENTIAL": 32, /* Addendum 2008-j */
    "ACCESS-POINT": 33,
    "ACCESS-RIGHTS": 34,
    "ACCESS-USER": 35,
    "ACCESS-ZONE": 36,
    "CREDENTIAL-DATA-INPUT": 37, /* authentication-factor-input */
    "NETWORK-SECURITY": 38, /* Addendum 2008-g */
    "BITSTRING-VALUE": 39, /* Addendum 2008-w */
    "CHARACTERSTRING-VALUE": 40, /* Addendum 2008-w */
    "DATE-PATTERN-VALUE": 41, /* Addendum 2008-w */
    "DATE-VALUE": 42, /* Addendum 2008-w */
    "DATETIME-PATTERN-VALUE": 43, /* Addendum 2008-w */
    "DATETIME-VALUE": 44, /* Addendum 2008-w */
    "INTEGER-VALUE": 45, /* Addendum 2008-w */
    "LARGE-ANALOG-VALUE": 46, /* Addendum 2008-w */
    "OCTETSTRING-VALUE": 47, /* Addendum 2008-w */
    "POSITIVE-INTEGER-VALUE": 48, /* Addendum 2008-w */
    "TIME-PATTERN-VALUE": 49, /* Addendum 2008-w */
    "TIME-VALUE": 50, /* Addendum 2008-w */
    "NOTIFICATION-FORWARDER": 51, /* Addendum 2010-af */
    "ALERT-ENROLLMENT": 52, /* Addendum 2010-af */
    "CHANNEL": 53, /* Addendum 2010-aa */
    "LIGHTING-OUTPUT": 54, /* Addendum 2010-i */
    "BINARY-LIGHTING-OUTPUT": 55, /* Addendum 135-2012az */
    "NETWORK-PORT": 56, /* Addendum 135-2012az */
    /* Enumerated values 0-127 are reserved for definition by ASHRAE. */
    /* Enumerated values 128-1023 may be used by others subject to */
    /* the procedures and constraints described in Clause 23. */
    /* do the max range inside of enum so that
    compilers will allocate adequate sized datatype for enum
    which is used to store decoding */
    "PROPRIETARY-MIN": 128,
    "PROPRIETARY-MAX": 1023,
    "MAX-BACNET-OBJECT-TYPE": 1024,
}

export const bacnetUnits = {
    // current
    'MILLIAMPERES': 'mA',
    'AMPERES': 'A',
    // voltage
    'VOLTS': 'V',
    'MILLIVOLTS': 'mV',
    'KILOVOLTS': 'kV',
    'MEGAVOLTS': 'MV',
    // power
    'KILOVOLT AMPERES': 'kVA',
    'KILOVOLT AMPERES REACTIVE': 'kVAR',
    'KILOWATTS': 'kW',
    'WATTS': 'W',
    'MILLIWATTS': 'mW',
    'MEGAWATTS': 'MW',
    'VOLT AMPERES': 'VA',
    'MEGAVOLT AMPERES': 'MVA',
    'VOLT AMPERES REACTIVE': 'VAR',
    'MEGAVOLT AMPERES REACTIVE': 'MVAR',
    'BTUS PER HOUR': 'Btu/hr',
    // energy
    'KILOWATT HOURS': 'kWh',
    'WATT HOURS': 'Wh',
    'MEGAWATT HOURS': 'MWh',
    'VOLT AMPERE HOURS': 'VAh',
    'KILOVOLT AMPERE HOURS': 'kVAh',
    'MEGAVOLT AMPERE HOURS': 'MVAh',
    'VOLT AMPERE HOURS REACTIVE': 'VARh',
    'KILOVOLT AMPERE HOURS REACTIVE': 'kVARh',
    'MEGAVOLT AMPERE HOURS REACTIVE': 'MVARh',
    'WATT HOURS REACTIVE': 'VARh',
    'KILOWATT HOURS REACTIVE': 'kVARh',
    'MEGAWATT HOURS REACTIVE': 'MVARh',
    'BTUS': 'Btu',
    'KILO BTUS': 'kBtu',
    'MEGA BTUS': 'MBtu',
    'TON HOURS': 'Ton Hrs',
    'THERMS': 'therms',
    'AMPERE SQUARE HOURS': 'Ah',
    // event counting
    // frequency
    'HERTZ': 'Hz',
    'KILOHERTZ': 'kHz',
    'MEGAHERTZ': 'MHz',
    'REVOLUTIONS PER MINUTE': 'RPM',
    // resistance
    'OHMS': 'Ohms',
    'KILOHMS': 'kohms',
    // mass
    'KILOGRAMS': 'kgs',
    'POUNDS MASS': 'Lbs',
    'TONS': 'Tons',
    // mass flow
    'KILOGRAMS PER HOUR': 'kg/hr',
    'POUNDS MASS PER HOUR': 'Lb/hr',
    // volume
    'US GALLONS': 'Gallons',
    'CUBIC FEET': 'Cubic Feet',
    'CUBIC METERS': 'Cubic Meters',
    'LITERS': 'Liters',
    // volume flow
    'CUBIC FEET PER SECOND': 'Cubic Feet/sec',
    'CUBIC FEET PER MINUTE': 'Cubic Feet/min',
    'CUBIC FEET PER HOUR': 'Cubic Feet/hr',
    'CUBIC METERS PER HOUR': 'Cubic Meters/hr',
    'US GALLONS PER MINUTE': 'Gallons per minute',
    'US GALLONS PER HOUR': 'Gallons per hour',
    'LITERS PER SECOND': 'Liters/sec',
    'LITERS PER MINUTE': 'Liters/min',
    'LITERS PER HOUR': 'Liters/hr',
    // velocity
    'METERS PER HOUR': 'MPH',
    'KILOMETERS PER HOUR': 'KPH',
    // temperature
    'DEGREES CELSIUS': 'Degrees C',
    'DEGREES FAHRENHEIT': 'Degrees F',
    // humidity
    'PERCENT RELATIVE HUMIDITY': '%RH',
    // phase
    'DEGREES PHASE': 'Degrees',
    // electrical
    'POWER FACTOR': 'PF',
    // intensity
    'WATTS PER SQUARE METER': 'W/m^2',
    // dimensionless
    'PERCENT': '%',
    'PARTS PER MILLION': 'PPM',
    // time
    'DAYS': 'days',
    'HOURS': 'hours',
    'MINUTES': 'min',
    'SECONDS': 'sec',
    'MILLISECONDS': 'ms',
    // pressure
    'PASCALS': 'Pa',
    'KILOPASCALS': 'kPa',
    'INCHES OF WATER': 'inWC',
    'INCHES OF MERCURY': 'inHg',
    'MILLIMETERS OF MERCURY': 'mmHg',
    'CENTIMETERS OF MERCURY': 'cmHg',
    // other
    'NO UNITS': ''
}