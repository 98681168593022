<template>
    <div id="deviceInfo">
        <b-button
            :class="configureDeviceInfo ? 'collapsed' : null"
            :aria-expanded="configureDeviceInfo ? 'true' : 'false'"
            aria-controls="collapse-1"
            @click="configureDeviceInfo = !configureDeviceInfo"
            variant="light"
            :title="popoverMsg"
        >
            <span class="icon">
                <i class="fa fa-info-circle"></i>
            </span>
            Device Info (Optional)
            <h5 style="display: inline;">
                <b-badge variant="info">
                    Status: {{configureDeviceInfo? ' Included' : ' N/A'}}
                </b-badge>
            </h5>
        </b-button>

        <b-collapse id="collapse-1" v-model="configureDeviceInfo" class="mt-2">
            <div>
                <h4>Serial Number</h4>
                <hr>
                <b-form-group
                    id="addressHexSNGroup"
                    label="Address:"
                    label-for="addressHexSN"
                    description="Type is hexadecimal number"
                    label-cols-sm="3"
                >
                    <div id="addressHexSN">
                        <label>0x</label>
                        <the-mask
                            class="hexInput"
                            v-model="serialNumber.address"
                            mask="FFFFFF"
                            :tokens="hexToken"
                        />
                    </div>
                </b-form-group>

                <b-form-group
                    id="countSNGroup"
                    label="Count:"
                    label-for="CountSNInput"
                    description="Type is positive decimal number"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="CountSNInput"
                        number
                        v-model="serialNumber.count"
                        :state="serialNumber.count>0"
                        placeholder="Enter number of registers"
                        :required="configureDeviceInfo"
                    />
                </b-form-group>

                
                <b-form-group
                    id="SNRegexGroup"
                    label-cols-sm="3"
                    label="Regex:"
                    label-for="SNRegexInput"
                    description=""
                >
                    <b-form-input
                        id="SNRegexInput"
                        type="text"
                        v-model="serialNumber.regex"
                        placeholder="i.e. AM[0-9]{8}"
                        :required="configureDeviceInfo"
                    >
                    </b-form-input>
                </b-form-group>
                
                <hr>
                <h4 v-if="identificationRegs.length > 0">Identification Registers</h4>
                <b-card
                    class="register-content"
                    v-for="(register, index) in identificationRegs"
                    :key='index'
                >   
                    <a
                        class="button is-danger is-outlined deleteBtn"
                        @click.prevent="removeRegister(index)"
                    >
                        <span class="icon">
                            <i class="fa fa-times"></i>
                        </span>
                    </a>
                    <br>

                    <b-form-group
                        id="labelGroup"
                        label-cols-sm="3"
                        label="Label:"
                        label-for="labelInput"
                        description=""
                    >
                        <b-form-input
                            id="labelInput"
                            type="text"
                            v-model="register.label"
                            placeholder=""
                            :required="configureDeviceInfo && identificationRegs.length > 0"
                        >
                        </b-form-input>
                    </b-form-group>

                     <b-form-group
                        id="addressHexGroup"
                        label="Address:"
                        label-for="addressHex"
                        description="Type is hexadecimal number"
                        label-cols-sm="3"
                    >
                        <div id="addressHex">
                            <label>0x</label>
                            <the-mask
                                class="hexInput"
                                v-model="register.address"
                                mask="FFFFFF"
                                :tokens="hexToken"
                            />
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="countGroup"
                        label="Count:"
                        label-for="CountInput"
                        description="Type is positive decimal number"
                        label-cols-sm="3"
                    >
                        <b-form-input
                            id="CountSNInput"
                            number
                            v-model="register.count"
                            :state="register.count > 0"
                            placeholder="Enter number of registers"
                            :required="configureDeviceInfo && identificationRegs.length > 0"
                        />
                    </b-form-group>

                    <b-form-group
                        id="regexGroup"
                        label-cols-sm="3"
                        label="Regex:"
                        label-for="regexInput"
                        description=""
                    >
                        <b-form-input
                            id="regexInput"
                            type="text"
                            v-model="register.regex"
                            placeholder="i.e. AM[0-9]{8}"
                            :required="configureDeviceInfo && identificationRegs.length > 0"
                        >
                        </b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="dataFormatGroup"
                        label="Data Format:"
                        label-for="dataFormatInput"
                        description=""
                        label-cols-sm="3"
                    >
                        <b-form-select
                            id="dataFormatInput"
                            v-model="register.dataFormat"
                            :options="regDataFormatOptions"
                            required  
                        >
                            <template slot="first">
                                <!-- this slot appears above the options from 'options' prop -->
                                <option
                                    :value="null"
                                    disabled
                                >
                                    -- Please select an option --
                                </option>
                            </template>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="byteOrderGroup"
                        label="Byte Order:"
                        label-for="byteOrderInput"
                        description=""
                        label-cols-sm="3"
                    >
                        <b-form-select
                            id="byteOrderInput"
                            v-model="register.byteOrder"
                            :options="regByteOrderOptions"
                            required  
                        >
                            <template slot="first">
                                <!-- this slot appears above the options from 'options' prop -->
                                <option
                                    :value="null"
                                    disabled
                                >
                                    -- Please select an option --
                                </option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-card>
                <b-button @click.prevent="addRegister" variant="light">
                    <span class="icon">
                        <i class="fa fa-plus"></i>
                    </span>
                    Additional Identification Registers
                </b-button>
            </div>
        </b-collapse>
    </div>
</template>
<script>
import { regDataFormatOptions, regByteOrderOptions } from '../fixtures/paramOptions';
import { MBT_DATA_TYPE_MAGIC_NUMBER, hexToken } from '../fixtures/constant';

const cloneDeep = require('clone-deep');

export default {
    name: 'DeviceInfo',
    props: ['deviceInfo'],
    data() {
        return {
            regDataFormatOptions,
            regByteOrderOptions,
            hexToken,

            configureDeviceInfo: false,
            serialNumber: {
                id: this.generateUUID(),
                label: 'Serial Number',
                address: '',
                count: null,
                regex: '',
                function: "READ_HOLDING_REGISTERS",
                dataType: 64250,
            },

            identificationRegs: [],
        }
    },
    components: {},
    computed: {
        popoverMsg() {
            return 'Toggle to ' + (this.configureDeviceInfo ? 'clear' : 'include') + ' optional device information'
        }
    },
    watch: {
        'deviceInfo': {
            handler: function (val) {
                this.updateDeviceInfo(val);
            },
            deep: true,
            immediate: true, //run it at initialization
        }
    },
    methods: {
        addRegister() {
            this.identificationRegs.push({
                id: this.generateUUID(),
                label: "",
                address: "",
                count: null,
                regex: "",
                function: "READ_HOLDING_REGISTERS",
                dataFormat: null,
                byteOrder: null,
                dataType: 64250,
            })
        },

        removeRegister(index) {
            this.identificationRegs.splice(index, 1);
        },

        updateDeviceInfo(deviceInfo) {
            if (deviceInfo && deviceInfo.length > 0) {
                const deviceInfoCloned = cloneDeep(deviceInfo);
                const serialNumber = deviceInfoCloned[0];
                
                this.serialNumber.id = serialNumber.id;
                this.serialNumber.address = serialNumber.address;
                this.serialNumber.count = serialNumber.count;
                this.serialNumber.regex = serialNumber.regex;
                
                const identificationRegs = deviceInfoCloned.slice(1);

                this.identificationRegs = identificationRegs.map(reg => {
                    reg.dataFormat = reg.dataType % 256;
                    reg.byteOrder = Math.floor(reg.dataType / 256);
                    
                    return reg;
                })
                this.configureDeviceInfo = true;
            }
            else {
                this.configureDeviceInfo = false;
            }
        },

        clearDeviceInfo() {
            this.configureDeviceInfo = false;

            this.serialNumber = {
                id: this.generateUUID(),
                label: 'Serial Number',
                address: '',
                count: null,
                regex: '',
                function: "READ_HOLDING_REGISTERS",
                dataType: 64250,
            };

            this.identificationRegs = [];
        },

        getDeviceInfo() {
            let deviceInfo = [];

            if (this.configureDeviceInfo === true) {
                if (this.serialNumber.address.length === 0) {
                    this.notify("Please specify the address of serial number", "warning");
                    return;
                }

                if (this.identificationRegs.length > 0) {
                    for (let reg of this.identificationRegs) {
                        if (reg.address.length === 0) {
                            this.notify("Please specify the address of the additional identification register", "warning");
                            return;
                        }
                    }
                }

                // serial number info
                deviceInfo.push(this.serialNumber);

                // additional identification registers
                this.identificationRegs.forEach(reg => {
                    reg.dataType = reg.byteOrder * MBT_DATA_TYPE_MAGIC_NUMBER + reg.dataFormat;
                    
                    const info = cloneDeep(reg);

                    delete info.byteOrder;
                    delete info.dataFormat;

                    deviceInfo.push(info);
                });
            }

            return deviceInfo;
        }
    },
    mounted() {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*#deviceModule {
    height: 100%;
}

.deviceCard {
    width: 100%;
    height: 100%;
    min-height: 250px;
}
*/
.card {
    width: 100%;
}

.register-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

.form-group {
    text-align: left !important;
}

.inner-button-container{
    position: absolute;
    bottom: 2px;
    right: 0;
    left: 0;
    text-align: center;
}

.button {
    margin: auto;
}

.button .icon:last-child {
    margin-left: auto;
    margin-right: auto;
}

.deleteBtn {
    float: right;
    width: 25px;
    height: 25px;
    margin-top: -15px;
    margin-right: -15px;
    margin-bottom: 10px;
}

br {
    clear: both;
}

.hexInput {
    display: inline-block;
    width: calc(100% - 14.8px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>