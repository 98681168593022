<template>
    <div id="app">
        <app-header></app-header>
        <div id="content">
            <router-view/>
        </div>
    </div>
</template>
<script>
import Header from './components/interface/header'

export default
{
    name: 'mainPage',
    components:
    {
        'app-header': Header
    }
}
</script>
<style>
/*html,body, #app, #wrap {
  min-height: 100%;
  height: 100%;
}*/

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /*margin-top: 60px;*/
}

#content {
    margin-top: 30px;
}
</style>