<template>
    <span id="editPointModal">
        <b-modal
            ref="editModal"
            title="Edit this point"
            @ok="editPoint"
            centered  
        >
            <b-form>
                <b-form-group
                    id="tabGroup"
                    label="Select tabs:"
                    label-for="tabSelect"
                    description="Create tabs at first if none exists"
                    label-cols-sm="3"
                >
                    <multiselect
                        id="tabSelect"
                        class="multiselect"
                        v-model="tab"
                        :options="tabOptions"
                        :multiple="true"
                        placeholder="Please select an option" 
                    />
                </b-form-group>

                <b-form-group
                    id="labelGroup"
                    label="Label:"
                    label-for="labelInput"
                    description="i.e., 'Phase A Voltage THD'"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="labelInput"
                        v-model="label"
                        placeholder="Enter label"
                        type="text"
                        required
                    />
                </b-form-group>

                <b-form-group
                    id="dataFormatGroup"
                    label="Data Format:"
                    label-for="dataFormatInput"
                    description=""
                    label-cols-sm="3"
                >
                    <b-form-select
                        id="dataFormatInput"
                        v-model="dataFormat"
                        :options="dataFormatOptions"
                        :disabled="isDataTypeFixed"
                        required  
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                -- Please select an option --
                            </option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <b-form-group
                    id="byteOrderGroup"
                    label="Byte Order:"
                    label-for="byteOrderInput"
                    description=""
                    label-cols-sm="3"
                >
                    <b-form-select
                        id="byteOrderInput"
                        v-model="byteOrder"
                        :options="byteOrderOptions"
                        :disabled="isDataTypeFixed"
                        required  
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                -- Please select an option --
                            </option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <b-form-group
                    id="dataTypeGroup"
                    label="Data Type:"
                    label-for="dataTypeInput"
                    description="Select data format and byte order to its determine data type"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="dataTypeInput"
                        number
                        v-model="dataType"
                        placeholder=""
                        readonly
                    />
                </b-form-group>

                <b-form-group
                    id="addressGroup"
                    label="Address:"
                    label-for="hexAddress"
                    description=""
                    label-cols-sm="3"
                >
                    <div class="hexAddress">
                        <label>0x</label>
                        <the-mask
                            class="hexInput"
                            v-model="addressHex"
                            mask="FFFFFF"
                            :tokens="hexToken"
                        />
                    </div>
                </b-form-group>

                <b-form-group
                    id="slopeGroup"
                    label="Slope:"
                    label-for="slopeInput"
                    description="i.e., 0.1"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="slopeInput"
                        number
                        v-model="slope"
                        placeholder="Enter slope"
                        required
                    />
                </b-form-group>
                
                <b-form-group
                    id="postLabelGroup"
                    label="Post Label:"
                    label-for="postLabelInput"
                    description="i.e., 'THD_Va'"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="postLabelInput"
                        type="text"
                        v-model="postLabel"
                        placeholder="Enter Post Label"
                        required
                    />
                </b-form-group>

                <b-form-group
                    id="cloudEnabledGroup"
                    label="Cloud Enabled:"
                    label-cols-sm="3" 
                >
                    <b-form-radio-group
                        id="cloudEnabledChecks"
                        v-model="cloudEnabled"
                        stacked
                    >
                        <b-form-radio :value="true">True</b-form-radio>
                        <b-form-radio :value="false">False</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>

                <b-form-group
                    id="unitsGroup"
                    label="Unit (optional):"
                    label-for="unitSelect"
                    description="e.g. % & kvarh"
                    label-cols-sm="3"
                >
                    <multiselect
                        id="unitSelect"
                        class="multiselect"
                        v-model="units"
                        :options="unitOptions"
                        :group-select="false"
                        :multiple="false"
                        group-values="libs"
                        group-label="group"
                        open-direction="top"
                        placeholder="Please select an option" 
                    />
                </b-form-group>
            </b-form>
        </b-modal>
        
        <b-button
            size="sm"
            variant="primary"
            @click.stop="showModal"
        >
            Edit
        </b-button>
    </span>
</template>

<script>
/* eslint-disable */
import Multiselect from 'vue-multiselect';
import {
    dataFormatOptions,
    byteOrderOptions,
    cloudEnabledOptions,
    unitOptions,
    postLabelOptions,
} from '../fixtures/paramOptions';
import { MBT_DATA_TYPE_MAGIC_NUMBER, hexToken } from '../fixtures/constant';

export default {
    name: 'EditPointModal',
    props: ['point', 'blockFunction', 'tabOptions'],
    data() {
        return {
            dataFormatOptions,
            byteOrderOptions,
            cloudEnabledOptions,
            postLabelOptions,
            unitOptions,
            hexToken,

            tab: [],
            label: this.point.label,
            dataFormat: null,
            byteOrder: null,
            dataType: this.point.dataType,
            addressHex: this.point.addressHex,
            slope: this.point.slope,
            postLabel: this.point.postLabel,
            cloudEnabled: this.point.cloudEnabled,
            units: this.point.units,
        }
    },
    components: {
        Multiselect
    },
    computed: {
        isDataTypeFixed: function() {
            if (this.blockFunction === 'READ_COILS' || this.blockFunction === 'READ_DISCRETE_INPUTS') {
                if (this.dataFormat !== 0 || this.byteOrder !== 0) {
                    // this.$notify.info({
                    //     content: 'Data format would be "UINT16" and Byte Order would be "NORMAL" for this block',
                    //     placement: 'top-center',
                    //     duration: 5000,
                    //     closable: true,
                    // })

                    this.dataFormat = 0;
                    this.byteOrder = 0;
                }

                return true;
            }

            return false;
        },

    },
    watch: {
        'point.tab': function(newVal) {
            if (newVal !== "") {
                this.tab = newVal.split('/');
            }
            else {
                this.tab = [];
            }
        },

        'point.label': function(newVal) {
            this.label = newVal;
        },

        'point.dataType': function(newVal) {
            this.dataType = newVal;
        },

        'point.addressHex': function(newVal) {
            this.addressHex = newVal.replace('0x', '');
        },

        'point.slope': function(newVal) {
            this.slope = newVal;
        },

        'point.postLabel': function(newVal) {
            this.postLabel = newVal;
        },

        'point.cloudEnabled': function(newVal) {
            this.cloudEnabled = newVal;
        },

        'point.units': function(newVal) {
            this.units = newVal;
        },

        byteOrder: function() {
            if (this.byteOrder === null || this.dataFormat === null) {
                return null
            }
            else {
                this.dataType = this.byteOrder * MBT_DATA_TYPE_MAGIC_NUMBER + this.dataFormat
            }
        },
        dataFormat: function() {
            if (this.byteOrder === null || this.dataFormat === null) {
                return null
            }
            else {
                this.dataType = this.byteOrder * MBT_DATA_TYPE_MAGIC_NUMBER + this.dataFormat
            }
        },
    },
    methods: {
        showModal() {
            this.$refs.editModal.show()
        },

        editPoint() {
            const corePointInfo = {
                tab: this.tab.join('/'),
                originalTab: this.point.tab,
                paramId: this.point.id,
                label: this.label,
                dataType: this.dataType,
                addressHex: '0x' + this.addressHex,
                slope: this.slope,
                postLabel: this.postLabel,
                cloudEnabled: this.cloudEnabled,
                units: this.units,
            };

            this.$emit('edit-point', JSON.parse(JSON.stringify(corePointInfo)), this.point.index); 
        }
            
    },

    mounted() {
        // change format
        if (this.point.tab !== "") {
            this.tab = this.point.tab.split('/');
        }

        this.addressHex = this.point.addressHex.replace('0x', '');
    }
}
</script>

<style>
#editPointModal {
    display: inline-block;
    margin-left: 2px;
}

</style>