import Vue from 'vue';
import Router from 'vue-router';
import homepage from './components/home.vue';
import login from './components/login.vue';
import templateList from './components/template_list.vue';
import Device from './components/configurations.vue';
import Logs from './components/logs.vue';
import Upload from './components/upload_components/upload_file.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/login',
        },
        {
            path: '/login',
            name: 'Login',
            component: login
        },
        {
            path: '/home',
            name: 'Homepage',
            component: homepage
        },
        {
            path: '/templates',
            name: 'Template List',
            component: templateList
        },
        {
            path: '/devices/:device',
            name: 'Device',
            component: Device
        },
        {
            path: '/upload',
            name: 'Upload',
            component: Upload
        },
        {
            path: '/logs',
            name: 'Logs',
            component: Logs
        }
    ]
})
