<template>
    <span id="editBlockModal">
        <b-modal
            ref="editModal"
            title="Edit this block"
            @ok="editBlock"
            centered  
        >
            <b-form>
                <b-form-group
                    id="startGroup"
                    label="Start Hex:"
                    label-for="startHex"
                    description=""
                    label-cols-sm="3"
                >
                    <div class="startHex">
                        <label>0x</label>
                        <the-mask
                            class="hexInput"
                            v-model="startHex"
                            mask="FFFFFF"
                            :tokens="hexToken"
                        />
                    </div>
                </b-form-group>
                <b-form-group
                    id="countGroup"
                    label-cols-sm="3"
                    label="Count:"
                    label-for="countInput"
                    description=""
                >
                    <b-form-input
                        id="countInput"
                        v-model="count"
                        number
                        required
                        placeholder="">
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    id="functionGroup"
                    label="Function:"
                    label-for="functionSelect"
                    label-cols-sm="3"
                >
                    <b-form-select
                        id="functionSelect"
                        v-model="func"
                        :options="blockFunctions"
                        required
                    />
                </b-form-group>
            </b-form>
        </b-modal>

        <b-button
            class="editBtn"
            size="sm"
            variant="primary"
            @click.stop="showModal"
        >
            Edit
        </b-button>
    </span>
</template>

<script>
import { blockFunctions } from '../fixtures/paramOptions';
import { hexToken } from '../fixtures/constant';

export default {
    name: 'EditBlockModal',
    props: ['blockIndex', 'blockStartHex', 'blockCount', 'blockFunction'],
    data() {
        return {
            hexToken,

            startHex: this.blockStartHex,
            count: this.blockCount,
            func: this.blockFunction,
            blockFunctions: blockFunctions,
        }
    },
    watch: {
        'blockStartHex': function(newVal) {
            this.startHex = newVal.replace('0x', '');
        },

        'blockCount': function(newVal) {
            this.count = newVal;
        },

        'blockFunction': function(newVal) {
            this.func = newVal;
        },

        'func': function(val) {
            if (val === 'READ_COILS' || val === 'READ_DISCRETE_INPUTS') {
                this.$notify.warning({
                    content: 'Data type of existing points in this block might be invalid for this new block function',
                    placement: 'top-center',
                    duration: 5000,
                    closable: true,
                })
            }
        }
    },
    methods: {
        showModal() {
            this.$refs.editModal.show()
        },

        editBlock() {
            this.$emit('edit-block', this.blockIndex, '0x' + this.startHex, Number(this.count), this.func)
        }
            
    },
    mounted() {
        this.startHex = this.blockStartHex.replace('0x', '');
    }
}
</script>

<style scoped>
#editBlockModal {
    display: inline-block;
    margin-left: 2px;
}

.editBtn {
    width: 52px;
}
</style>