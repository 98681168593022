<template>
    <div id="deviceModule">
        <b-card class="deviceCard" title="Device" align="left">
            <hr>
            <b-form @submit="onSaveDevice">
                <b-form-group
                    id="modelGroup"
                    label-cols-sm="3"
                    label="Device Model:"
                    label-for="modelInput"
                    description="Device model must be defined"
                >
                    <b-form-input
                        id="modelInput"
                        type="text"
                        v-model="label"
                        :readonly="!isNewModel"
                        placeholder="Enter device model"
                        required
                    >
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    id="versionGroup"
                    label-cols-sm="3"
                    label="Device Version:"
                    label-for="versionInput"
                    description="Device version must be unique"
                >
                    <b-form-input
                        id="versionInput"
                        type="text"
                        v-model="version"
                        placeholder="Enter device version"
                        required
                    >
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    id="cloudModelGroup"
                    label-cols-sm="3"
                    label="Cloud Model:"
                    label-for="cloudModelInput"
                    description="A model defined in AcuCloud, e.g., 'Acuvim II'"
                >
                    <b-form-input
                        id="cloudModelInput"
                        type="text"
                        v-model="cloudModel"
                        :readonly="!isNewModel || isTypicalModel"
                        placeholder="Enter cloud model"
                        required
                    >
                    </b-form-input>
                </b-form-group>

                 <b-form-group
                    id="officialGroup"
                    label="Official:"
                    label-cols-sm="3" 
                >
                    <b-form-radio-group
                        id="officialChecks"
                        v-model="isOfficial"
                        :options='officialOptions'
                        stacked
                    />
                </b-form-group>
                
                <device-info 
                    ref="deviceInfo"
                    :device-info="deviceInfo"
                />

                <br>
                <div class="inner-button-container">
                    <b-button
                        class="button"
                        type="submit"
                        variant="primary"
                    >
                        Save device
                    </b-button>
                </div>
            </b-form>
        </b-card>
    </div>
</template>
<script>
import DeviceInfo from '../generics/DeviceInfo.vue';
import { officialOptions } from '../fixtures/paramOptions';

export default {
    name: 'DeviceModule',
    props: ['originalLabel', 'originalCloudModel', 'originalIsOfficial', 'isNewModel', 'isTypicalModel', 'deviceInfo'],
    data() {
        return {
            officialOptions,
            label: this.originalLabel,
            version: '',
            cloudModel: this.originalCloudModel,
            deviceModels: {},
            isOfficial: this.originalIsOfficial,
        }
    },
    components: {
        'device-info': DeviceInfo,
    },
    computed: {},
    watch: {
        originalLabel: function (val) {
            this.label = val
        },

        originalCloudModel: function (val) {
            this.cloudModel = val
        },

        originalIsOfficial: function (val) {
            this.isOfficial = val
        },
    },
    methods: {
        onSaveDevice(evt) {
            evt.preventDefault();
            const existingModels = Object.keys(this.deviceModels);

            if (existingModels.indexOf(this.label) !== -1) {
                if (this.isNewModel) {
                    this.notify("Model exists", "danger");
                    return
                }
                else {
                    if (this.deviceModels[this.label].indexOf(this.version) !== -1) {
                        this.notify("Version exists", "danger");
                        return
                    }
                }
            }

            const deviceInfo = this.$refs.deviceInfo.getDeviceInfo();
            

            this.$emit('save', this.label, this.version, this.cloudModel, this.isOfficial, deviceInfo);
        }
    },
    mounted() {
        this.request('get', '/api/template/modbus/')
        .then(response => {
            var deviceModels = {}
            // var fields = {}
            var devices = response.data

            if (devices) {
                var len = devices.length

                for (var i = 0; i < len; i++) {
                    var modelName = devices[i].name
                    // var field = deviceModel + ' ' + devices[i].version

                    if (!deviceModels.hasOwnProperty(modelName)) {
                        deviceModels[modelName] = []
                    }
                    deviceModels[modelName].push(devices[i].version)
                }
            }
            this.deviceModels = deviceModels
        })
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#deviceModule {
    height: 100%;
}

.deviceCard {
    width: 100%;
    height: 100%;
    min-height: 250px;
}

.card {
    width: 100%;
}

.register-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

.form-group {
    text-align: left !important;
}

.inner-button-container{
    position: absolute;
    bottom: 2px;
    right: 0;
    left: 0;
    text-align: center;
}

.button {
    margin: auto;
}

.button .icon:last-child {
    margin-left: auto;
    margin-right: auto;
}

.deleteBtn {
    float: right;
    width: 25px;
    height: 25px;
    margin-top: -15px;
    margin-right: -15px;
    margin-bottom: 10px;
}

br {
    clear: both;
}

.hexInput {
    display: inline-block;
    width: calc(100% - 14.8px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>