<template>
    <span id="deletePointModal">
        <b-modal
            ref="deleteModal"
            title="Are you sure to delete this point?"
            @ok="deletePoint"
        >
            Configuartions for this point will be discarded
        </b-modal>

        <b-button
            size="sm"
            variant="danger"
            @click.stop="showModal"
        >
            Delete
        </b-button>
    </span>
</template>

<script>
export default {
    name: 'DeletePointModal',
    data() {
        return {}
    },
    props: ['pointIndex', 'tabLabel'],
    methods: {
        showModal() {
            this.$refs.deleteModal.show()
        },

        deletePoint() {
            this.$emit('delete-point', this.pointIndex, this.tabLabel)
            // return this.$parent.$parent.deletePoint(this.pointIndex, this.tabLabel)
        }
    },

    mounted() {}
}
</script>

<style>
#deletePointModal {
    display: inline-block;
    margin-left: 2px;
}

</style>