<template>
    <div id="pointModule">
        <b-form-group
            label="Add one or more tabs for this parameter (optional)"
            label-align="left"
            label-size="lg"
        >
            <b-form-radio-group
                class="radios"
                v-model="selectTab"
                name=""
                stacked
            >
                <b-form-radio value="create">Create new tabs</b-form-radio>
                <b-form-radio value="select">Select tabs</b-form-radio>
            </b-form-radio-group>
        </b-form-group>

        <b-card
            v-if="selectTab==='create'"
            style="width: 100%;"
        >
            <b-form @submit.prevent="onSaveTab">
                <b-form-group
                    id="tabLabelGroup"
                    label="Tab Label:"
                    label-for="tabLabelInput"
                    description="Click on radio button 'Select a tab' to use it after saving"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="tabLabelInput"
                        v-model="tabForm.label"
                        type="text"
                        placeholder="Enter tab label, i.e., 'Basic Metering'"
                        required 
                    />
                </b-form-group>
                
                <b-button
                    class="button"
                    type="submit"
                    variant="primary"
                >
                    Save tab
                </b-button>
            </b-form>
        </b-card>

        <b-card
            v-if="selectTab==='select'"
            style="width: 100%;"
        >
            <b-form-group
                id="tabGroup"
                label="Select one or more tabs:"
                label-for="tabSelect"
                description="Create new tabs if none exists"
                label-cols-sm="3"
            >
                <multiselect
                    id="tabSelect"
                    class="multiselect"
                    v-model="selectedTabLabels"
                    :options="tabOptions"
                    :multiple="true"
                    placeholder="Please select an option" 
                />
            </b-form-group>
        </b-card>
        <br>

        <b-card style="width: 100%;" title="Parameter" align="left">
            <hr>
            <b-form @submit.prevent="onSaveParam">
                <b-form-group
                    id="blockGroup"
                    label="Select a block:"
                    label-for="blockSelect"
                    description="Create new blocks if none exists"
                    label-cols-sm="3"
                >
                    <b-form-select
                        id="blockSelect"
                        v-model="selectedBlockLabel"
                        :options="blockOptions"
                        required   
                    >
                        <template slot="first">
                            <!-- this slot appears above the options from 'options' prop -->
                            <option
                                :value="''"
                                disabled
                            >
                                -- Please select an option --
                            </option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <b-form-group
                    id="labelGroup"
                    label="Label:"
                    label-for="labelInput"
                    description="i.e., 'Phase A Voltage THD'"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="labelInput"
                        v-model="paramForm.label"
                        placeholder="Enter label"
                        type="text"
                        required
                    />
                </b-form-group>
                
                 <b-form-group
                    id="addressGroup"
                    label="Address:"
                    label-for="hexAddress"
                    :description="hexAddressDesc"
                    label-cols-sm="3"
                >
                    <div class="hexAddress">
                        <label>0x</label>
                        <the-mask
                            class="hexInput"
                            v-model="paramForm.addressHex"
                            mask="FFFFFF"
                            :tokens="hexToken"
                        />
                    </div>
                </b-form-group>
                
                <b-form-group
                    id="slopeGroup"
                    label="Slope:"
                    label-for="slopeInput"
                    description="i.e., 0.1"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="slopeInput"
                        number
                        v-model="paramForm.slope"
                        placeholder="Enter slope"
                        required
                    />
                </b-form-group>

                <b-form-group
                    id="postLabelGroup"
                    label="Post Label:"
                    label-for="postLabelInput"
                    description="i.e., 'THD_Va'"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="postLabelInput"
                        type="text"
                        v-model="paramForm.postLabel"
                        placeholder="Enter Post Label"
                        required
                    />
                </b-form-group>
                
                <b-form-group
                    id="cloudEnabledGroup"
                    label="Cloud Enabled:"
                    label-cols-sm="3" 
                >
                    <b-form-radio-group
                        id="cloudEnabledChecks"
                        v-model="paramForm.cloudEnabled"
                        :options='cloudEnabledOptions'
                        stacked
                    />
                </b-form-group>
                
                <b-form-group
                    id="dataFormatGroup"
                    label="Data Format:"
                    label-for="dataFormatInput"
                    description=""
                    label-cols-sm="3"
                >
                    <b-form-select
                        id="dataFormatInput"
                        v-model="dataFormat"
                        :options="dataFormatOptions"
                        :disabled="isDataTypeFixed"
                        required  
                    >
                        <template slot="first">
                            <!-- this slot appears above the options from 'options' prop -->
                            <option
                                :value="null"
                                disabled
                            >
                                -- Please select an option --
                            </option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <b-form-group
                    id="byteOrderGroup"
                    label="Byte Order:"
                    label-for="byteOrderInput"
                    description=""
                    label-cols-sm="3"
                >
                    <b-form-select
                        id="byteOrderInput"
                        v-model="byteOrder"
                        :options="byteOrderOptions"
                        :disabled="isDataTypeFixed"
                        required  
                    >
                        <template slot="first">
                            <!-- this slot appears above the options from 'options' prop -->
                            <option
                                :value="null"
                                disabled
                            >
                                -- Please select an option --
                            </option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <b-form-group
                    id="dataTypeGroup"
                    label="Data Type:"
                    label-for="dataTypeInput"
                    description="Select data format and byte order to determine its data type"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="dataTypeInput"
                        number
                        :value="dataType"
                        placeholder=""
                        readonly
                    />
                </b-form-group>

                <b-form-group
                    id="unitGroup"
                    label="Unit (optional):"
                    label-for="unitSelect"
                    description="e.g. % & kvarh"
                    label-cols-sm="3"
                >   
                    <multiselect
                        id="unitSelect"
                        class="multiselect"
                        v-model="paramForm.units"
                        :options="unitOptions"
                        :group-select="false"
                        :multiple="false"
                        group-values="libs"
                        group-label="group"
                        open-direction="top"
                        placeholder="Please select an option" 
                    />
                </b-form-group>
                
                <div class="inner-button-container">
                    <b-button
                        class="button"
                        type="submit"
                        variant="primary"
                    >
                        Save parameter
                    </b-button>
                </div>
            </b-form>
        </b-card>
    </div>
</template>

<script>
/* eslint-disable */
import Multiselect from 'vue-multiselect';
import {
    dataFormatOptions,
    byteOrderOptions,
    cloudEnabledOptions,
    unitOptions,
    postLabelOptions,
} from '../fixtures/paramOptions';
import { MBT_DATA_TYPE_MAGIC_NUMBER, hexToken } from '../fixtures/constant';

const converter = require('hex2dec');

export default {
    name: 'PointModule',
    props: ['blocks', 'blockOptions', 'tabOptions', 'paramOptions'],
    data() {
        return {
            dataFormatOptions,
            byteOrderOptions,
            cloudEnabledOptions,
            postLabelOptions,
            unitOptions,
            hexToken,

            decimalBlockRange: {
                decimalBlockStart: null,
                decimalBlockEnd: null,
            },

            selectTab: 'select',
            tabForm: {
                id: this.generateUUID(),
                label: '',
                params: []
            },

            paramForm: {
                unsaved: true,
                id: this.generateUUID(),
                label: '',
                address: null,
                addressHex: '',
                dataType: '',
                slope: 1,
                postLabel: '',
                cloudEnabled: true,
                slopeEnabled: true,
                units: '',
                canEditUnits: null,
                canEditName: null,
                offsetEnabled: null,
                offset: null,
                multiplierRegisterEnabled: null,
                multiplierRegister: null,
                userScaleEnabled: null,
                invalidValueEnabled: null,
                invalidValue: null
            },

            dataFormat: null,
            byteOrder: null,

            selectedBlockLabel: '',
            selectedTabLabels: [],
            selectedBlockIndex: null,
        }
    },
    components: {
        Multiselect
    },
    computed: {
        dataType: function() {
            if (this.byteOrder === null || this.dataFormat === null) {
                return null
            } else {
                var dataType = this.byteOrder * MBT_DATA_TYPE_MAGIC_NUMBER + this.dataFormat
                this.paramForm.dataType = dataType
                return dataType
            }
        },
        regNum: function() {
            switch (this.dataFormat) {
                case 0:
                case 1:
                case 11:
                    return 1
                case 2:
                case 3:
                case 4:
                case 7:
                case 10:
                    return 2
                case 6:
                case 9:
                    return 3
                case 5:
                case 8:
                    return 4
                default:
                    return 1
            }
        },

        isDataTypeFixed: function() {
            // console.log(this.selectedBlockIndex, this.isDataTypeFixed)
            if (this.selectedBlockIndex !== null) {
                const blockFunction = this.blocks[this.selectedBlockIndex].function;

                if (blockFunction === 'READ_COILS' || blockFunction === 'READ_DISCRETE_INPUTS') {
                    if (this.dataFormat !== 0 || this.byteOrder !== 0) {
                        // this.$notify.info({
                        //     content: 'Data format would be "UINT16" and Byte Order would be "NORMAL" for this block',
                        //     placement: 'top-center',
                        //     duration: 5000,
                        //     closable: true,
                        // })

                        this.dataFormat = 0;
                        this.byteOrder = 0;
                    }

                    return true;
                }
            }

            return false;
        },

        hexAddressDesc: function() {
            if (this.selectedBlockLabel !== '') {

                var blockLabel = this.selectedBlockLabel

                var slashIndex = blockLabel.indexOf("-")
                var firstColonIndex = blockLabel.indexOf(":")
                var lastColonIndex = blockLabel.lastIndexOf(":")

                var hexBlockStartAddress = blockLabel.slice(firstColonIndex + 2, slashIndex - 1)
                var hexBlockEndAddress = blockLabel.slice(slashIndex + 2, lastColonIndex - 1)

                var decimalStart = Number(converter.hexToDec(hexBlockStartAddress))
                if (hexBlockStartAddress === '0x0') {
                    decimalStart = 0
                }
                var decimalEnd = Number(converter.hexToDec(hexBlockEndAddress))
                if (hexBlockEndAddress === '0x0') {
                    decimalEnd = 0
                }

                // save block range
                this.decimalBlockRange.decimalBlockStart = decimalStart
                this.decimalBlockRange.decimalBlockEnd = decimalEnd
                this.selectedBlockIndex = Number(blockLabel.slice(6, firstColonIndex - 1))

                decimalEnd = decimalEnd - this.regNum + 1

                if (decimalEnd < decimalStart) {
                    this.$notify.warning({
                        content: 'Block running out of space',
                        placement: 'top-center',
                        duration: 5000,
                        closable: true,
                    })
                    return "Address of its beginning register. Range : running out of space"
                } else {
                    var hexStart = converter.decToHex(String(decimalStart))
                    if (hexStart === null) {
                        hexStart = '0x0'
                    }
                    var hexEnd = converter.decToHex(String(decimalEnd))
                    if (hexEnd === null) {
                        hexEnd = '0x0'
                    }
                    hexStart = hexStart.slice(0, 2) + hexStart.slice(2).toLocaleUpperCase()
                    hexEnd = hexEnd.slice(0, 2) + hexEnd.slice(2).toLocaleUpperCase()
                    return "Address of its beginning register. Range : " + hexStart + " - " + hexEnd
                }
            } else {
                return "Address of its beginning register"
            }
        }
    },
    watch: {},
    methods: {
        onSaveTab(evt) {
            evt.preventDefault();
            var message = ''
            var messageType = 'danger'
            var tabLabel = this.tabForm.label
            if (tabLabel === '') {
                message = 'Tab label undefined'
            } else {
                if (this.tabOptions.indexOf(tabLabel) === -1) {
                    // this.tabOptions.push(tabLabel)
                    // deep clone
                    var tabForm = JSON.parse(JSON.stringify(this.tabForm))
                    // this.modelForm.meta.tabs.push(tabForm)
                    // this.$parent.$parent.$parent.saveTab(tabForm, tabLabel)
                    this.$emit('save-tab', tabForm, tabLabel)

                    message = 'Tab saved'
                    messageType = 'success'
                    this.resetTab()
                } else {
                    message = 'Tab exists'
                }
            }
            this.$notify.open({
                content: message,
                placement: 'top-center',
                type: messageType,
                duration: 5000,
                closable: true,
            })
        },
        resetTab() {
            this.tabForm.id = this.generateUUID()
            this.tabForm.label = ''
            this.tabForm.params = []
        },
        onSaveParam(evt) {
            evt.preventDefault()
            var paramAddress = '0x' + this.paramForm.addressHex

            paramAddress = Number(converter.hexToDec(paramAddress))
            if (paramAddress === null) {
                paramAddress = 0
            }

            if (paramAddress >= this.decimalBlockRange.decimalBlockStart &&
                paramAddress + this.regNum <= this.decimalBlockRange.decimalBlockEnd + 1) {

                var paramID = this.paramForm.id
                var paramIndex = this.paramOptions.indexOf(paramID)
                if (paramIndex === -1) {
                    this.paramForm.address = paramAddress
                    // deep clone
                    var paramForm = JSON.parse(JSON.stringify(this.paramForm))

                    // Ensure the type
                    paramForm.slope = Number(paramForm.slope)

                    // this.$parent.$parent.$parent.savePoint(paramForm, paramID, this.selectedTabLabels, this.selectedBlockIndex)
                    this.$emit('save-point', paramForm, paramID, this.selectedTabLabels, this.selectedBlockIndex)

                    this.resetParam();
                    this.notify("Parameter saved", "success");
                } else {
                    this.notify("Parameter exists", "danger");
                }
            } else {
                this.notify("Parameter address is not in the range", "danger");
            }
        },
        resetParam() {
            /* Reset our form values */
            this.paramForm.id = this.generateUUID()
            this.paramForm.label = ''
            this.paramForm.address = null
            this.paramForm.addressHex = ''
            this.dataFormat = null
            this.byteOrder = null
            this.paramForm.dataType = ''
            this.paramForm.slope = 1
            this.paramForm.postLabel = ''
            this.paramForm.cloudEnabled = true
            this.paramForm.units = ''
        }
    },
    mounted: function() {}
}
</script>
<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.form-group {
    text-align: left !important;
}

.button {
    margin-right: 2px;
    /*background-color: #3c81df !important;*/
}

.inner-button-container{
    position: absolute;
    bottom: 2px;
    right: 0;
    left: 0;
    text-align: center;
}

.hexInput {
    display: inline-block;
    width: calc(100% - 14.8px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.radios {
    float: left;
    text-align: left;
}
</style>