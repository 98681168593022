<template>
    <center>
        <b-card
            :header="'Create a new device template based on a ' + fileType + ' file'"
            align="left"
            style="width: 40%;"
        >
            <b-form @submit.prevent="uploadFile" @reset.prevent="clear">
                <b-form-group
                    id="modelGroup"
                    label-cols-sm="3"
                    label="Device model:"
                    label-for="modelInput"
                >
                    <b-form-input
                        id="modelInput"
                        type="text"
                        v-model="name"
                        required
                        placeholder="Enter device model"
                    >
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    id="versionGroup"
                    label-cols-sm="3"
                    label="Device version:"
                    label-for="versionInput"
                    description="Please specify a unique version number"
                    :invalid-feedback="invalidFeedback"
                >
                    <b-form-input
                        id="versionInput"
                        type="text"
                        v-model="version"
                        required
                        :state="versionState"
                        placeholder="Enter device version">
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    id="cloudModelGroup"
                    label-cols-sm="3"
                    label="Cloud model:"
                    label-for="cloudModelInput"
                    description="To be used in AcuCloud, it must be a model defined in AcuCloud, e.g., 'Acuvim II'"
                >
                    <b-form-input
                        id="cloudModelInput"
                        type="text"
                        v-model="cloudModel"
                        required
                        placeholder="Enter cloud model">
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    id="uploadFileGroup"
                    label-cols-sm="3"
                    label="Upload file:"
                    label-for="uploadFileForm"
                    description="Support file drag and drop"
                >   
                    <b-form-file
                        v-if='fileType === "EPICS"'
                        v-model="file"
                        :state="Boolean(file)"
                        :placeholder="'Choose a EPICS file...'">
                    </b-form-file>
                    <b-form-file
                        v-else
                        v-model="file"
                        :state="Boolean(file)"
                        :placeholder="'Choose a file (' + fileExtension + ')...'">
                    </b-form-file>
                </b-form-group>
                
                <b-form-group
                    id="officialGroup"
                    label="Official:"
                    label-cols-sm="3" 
                >
                    <b-form-radio-group
                        id="officialChecks"
                        v-model="isOfficial"
                        :options='officialOptions'
                        stacked
                    />
                </b-form-group>

                <device-info ref="deviceInfo"/>
                
                <div id="fileBtns">
                    <b-button
                        size="sm"
                        class="button"
                        type="submit"
                        variant="success"
                        :disabled='!versionState'
                    >
                        Upload
                    </b-button>

                    <b-button
                        size="sm"
                        class="button"
                        type="reset"
                        variant="danger"
                    >
                        Clear
                    </b-button>
                </div>
            </b-form>
        </b-card>

        <bacnet-point-modal
            ref="bacnetPointModule"
            @filter-selected-points="uploadBACnetJSON"
        />

    </center>
</template>

<script>
import DeviceInfo from '../generics/DeviceInfo.vue';
import BACnetPointModal from './bacnet_point_modal.vue';
import {
    pointTemplate,
    objectTypeOptions,
    bacnetUnits
} from '../fixtures/bacnetFixtures.js'
import { officialOptions } from '../fixtures/paramOptions';

const FormData = require('form-data');
const cloneDeep = require('clone-deep');

export default {
    name: 'UploadFile',
    props: {},
    components: {
        'bacnet-point-modal': BACnetPointModal,
        'device-info': DeviceInfo,
    },
    data() {
        return {
            officialOptions,

            name: '',
            version: '',
            cloudModel: '',
            file: null,
            existingModels: {},
            modelUUIDs: {},

            lastUploadedfile: {
                name: '',
                version: '',
                cloudModel: ''
            },
            isOfficial: true,

            deviceInfo: [],
            points: [],
        }
    },
    computed: {
        versionState () {
            if (this.version === ''){
                return false
            }

            const existingVersions = this.existingModels[this.name];

            if (existingVersions !== undefined && existingVersions.indexOf(this.version) !== -1) {
                return false;
            }

            return true
        },

        invalidFeedback () {
            if (this.versionState || this.version === '') {
                return ""
            }

            return "Version should be unique. Can't upload " + this.fileType + " files with current version number";
        },

        fileType() {
            const path = this.$route.fullPath;

            if (path.includes('mbt')) {
                return 'MBT';
            }
            else if (path.includes('csv')) {
                return 'CSV';
            }
            else if (path.includes('epics')) {
                return 'EPICS';
            }
            else {
                return 'CSV';
            }
        },

        templateType() {
            const path = this.$route.fullPath;

            if (path.includes('bacnet')) {
                return 'bacnet';
            }

            return 'modbus';
        },

        fileExtension() {
            return '.' + this.fileType.toLowerCase();
        },
    },
    watch: {
    },
    methods: {
        addRegister() {
            this.identificationRegs.push({
                id: this.generateUUID(),
                label: "",
                address: "",
                count: null,
                regex: "",
                function: "READ_HOLDING_REGISTERS",
                dataType: 20190712
            })
        },

        removeRegister(index) {
            this.identificationRegs.splice(index, 1);
        },

        convertBACnetToJSON() {
            const read = new FileReader();

            read.readAsText(this.file);

            read.onloadend = () => {
                // By lines
                let points = [];
                const lines = read.result.split('\n');
                const fileLen = lines.length;
                let appearTime = 0
                let paramLineStartIndex = 0;
                let paramLineEndIndex = fileLen - 1;
                
                // Convert EPICS to JSON
                for(let i = 0; i < fileLen; i++) {
                    if (lines[i].includes("object-identifier")) {
                        appearTime++;

                        // valid content starts from the line of second "object-identifier"
                        if (appearTime === 2) {
                            paramLineStartIndex = i - 1;
                            break;
                        }
                    }
                }

                for(let i = fileLen - 1; i >= 0; i--) {
                    if (lines[i].includes("End of BACnet Protocol Implementation Conformance Statement")) {
                        paramLineEndIndex = i;
                        break;
                    }
                }

                // Convert EPICS to JSON
                for(let i = paramLineStartIndex; i < paramLineEndIndex; i++) {
                    const line = lines[i];
                    // console.log(i , line, points[0]);
                    // add a new point 
                    if (line.includes('{')) {
                        points.unshift(JSON.parse(JSON.stringify(pointTemplate)));
                        points[0]['uuid'] = this.generateUUID();
                    }

                    if (line.includes(":")) {
                        const keyValuePair = line.split(':');
                        const key = keyValuePair[0].trim();
                        let value = keyValuePair[1].trim();

                        // remove quotes at ends
                        value = value[0] === '"' ? value.slice(1, -1) : value;

                        // update the first point in the points array
                        if (key === 'object-name') {
                            points[0]['postLabel'] = value;
                            points[0]['label'] = value;
                        }
                        // if (key === 'description') {
                        //     points[0]['label'] = value.slice(1, -1);
                        // }
                        if (key === 'object-identifier') {
                            // extract the number as object id
                            points[0]['objectId'] = Number(value.replace(/[^0-9]/g,''));
                        }
                        if (key === 'object-type') {
                            points[0]['objectType'] = objectTypeOptions[value.toUpperCase()];
                        }
                        if (key === 'units') {
                            const unit = bacnetUnits[value.toUpperCase().replace(/_|-/g, " ")];

                            if (unit === undefined) {
                                const errMsg = "Detect a invalid unit " + "(" + value + ") " + "at line " + (i + 1) + ".";

                                this.notify(errMsg, "danger");
                                return;
                            }

                            points[0]['units'] = unit;
                        }
                    }
                }

                // reverse points array
                points = points.reverse();
                this.points = points;
                this.$refs.bacnetPointModule.initializeModal(points);
            }
        },

        uploadBACnetJSON(selectedPointsIndex) {
            if (selectedPointsIndex.length === 0) {
                this.notify("No points available to generate the BACnet template", "warning");
                return;
            }

            let selectedPoints = [];
            
            selectedPointsIndex.forEach(pointIndex => {
                selectedPoints.push(this.points[pointIndex]);
            })

            const tabParamList = selectedPoints.map(point => point.uuid);
            let jsonForm = {
                "uuid": this.modelUUIDs[this.name] || this.generateUUID(),
                "label": this.name,
                "version": this.version,
                "official": this.isOfficial,
                "last_updated_at": Math.floor(Date.now() / 1000),
                "meta": {
                    "tabs": [
                        {
                            "id": this.generateUUID(),
                            "params": tabParamList,
                            "label": "Readings"
                        }
                    ],
                    "classes": [
                        {
                            "label": this.cloudModel,
                            "models": [
                                {
                                    "meta": {
                                        "protocols": {
                                            "bacnet": {
                                                "ip": true,
                                                "ms/tp": true
                                            }
                                        }
                                    },
                                    "label": this.cloudModel
                                }
                            ]
                        }
                    ],
                    "points": selectedPoints
                }
            }

            if (this.deviceInfo.length > 0) {
                jsonForm.meta.deviceInfo = this.deviceInfo;
            }

            let payload = {
                "name": this.name,
                "version": this.version,
                "json": jsonForm
            }
            this.post('/api/template/bacnet/', payload);
        },

        uploadFile() {
            // in case user attempts to upload a device multiple times
            if (this.lastUploadedfile.name === this.name &&
                this.lastUploadedfile.version === this.version &&
                this.lastUploadedfile.cloudModel === this.cloudModel) {

                this.notify("Device exists.<br>Please redefine device name or version", "danger");
                return;
            }

            if (!this.file) {
                this.notify("Please upload a file", "danger");
                return;
            }

            let deviceInfo = this.$refs.deviceInfo.getDeviceInfo();
            
            // deviceInfo.forEach(info => info.address = this.hexToDec(info.address));
            this.deviceInfo = deviceInfo.map(info => {
                let formattedInfo = cloneDeep(info);
                formattedInfo.address = this.hexToDec(info.address);

                return formattedInfo;
            });

            if (this.fileType === 'EPICS') {
                this.convertBACnetToJSON();
            }
            else {
                let formData = new FormData()
                
                formData.append('name', this.name)
                formData.append('version', this.version)
                formData.append('cloudlabel', this.cloudModel)
                formData.append('official', this.isOfficial)
                formData.append('device_info', JSON.stringify(this.deviceInfo))
                formData.append('file', this.file)

                this.post('/api/template/' + this.templateType + '/', formData)
            }
        },

        post(url, payload) {
            this.request('POST', url, payload)
            .then(response => {
                this.notify(response.data.message, "success");
                this.lastUploadedfile.name = this.name;
                this.lastUploadedfile.version = this.version;
                this.lastUploadedfile.cloudModel = this.cloudModel;

                // add this template to existing models
                if (!this.existingModels.hasOwnProperty(this.name)) {
                    this.existingModels[this.name] = [this.version];

                    // save the new uuid for new created models
                    if (typeof payload === 'object' && payload.json) {
                        this.modelUUIDs[this.name] = payload.json.uuid;
                    }
                }
                else {
                    this.existingModels[this.name].push(this.version);
                }

                this.version = '';
            })
        },

        clear() {
            this.name = '';
            this.version = '';
            this.file = null;
            this.cloudModel = null;
            this.lastUploadedfile.name = '';
            this.lastUploadedfile.version = '';

            this.$refs.deviceInfo.clearDeviceInfo();
        }
    },

    mounted: function() {
        this.request('get', '/api/template/' + this.templateType + '/')
        .then(response => {
            const devices = response.data;
            let deviceModels = {};
            let modelUUIDs = {};
            let modelName;

            devices.forEach(device => {
                modelName = device.name;
                    
                if (!deviceModels.hasOwnProperty(modelName)) {
                    deviceModels[modelName] = [];
                }

                deviceModels[modelName].push(device.version);
                modelUUIDs[modelName] = device.uuid;
            })

            this.modelUUIDs = modelUUIDs;
            this.existingModels = deviceModels
        })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#fileBtns {
    float: right;
}

.card {
    width: 100%;
}

.register-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

.button {
    margin-left: 2px;
}

.button .icon:last-child {
    margin-left: auto;
    margin-right: auto;
}

.deleteBtn {
    float: right;
    width: 25px;
    height: 25px;
    margin-top: -15px;
    margin-right: -15px;
    margin-bottom: 10px;
}

br {
    clear: both;
}

.hexInput {
    display: inline-block;
    width: calc(100% - 14.8px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>