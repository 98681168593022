<template>
    <div>
        <b-spinner
            class="spinner"
            v-if="!isReady"
            variant="secondary"
        />
        <transition name="fade">
            <b-container fluid v-if="isReady">
                <b-row>
                    <b-col
                        xs="12"
                        sm="12"
                        md="10"
                        offset-md="1"
                        lg="8"
                        offset-lg="2"
                        style="min-width: 800px"
                    >
                        <b-card
                            class="template-list"
                            :title="pageTitle"
                        >
                            <hr>
                            <b-tabs pills card vertical>
                                <!-- Render Tabs -->
                                <b-tab
                                    class="my-tab"
                                    :title="`${model}`"
                                    v-for="model in Object.keys(deviceModels)"
                                    :key="model"
                                >
                                    <b-table
                                        hover
                                        striped
                                        outlined
                                        show-empty
                                        stacked="sm"
                                        :items="deviceModels[model]"
                                        :fields="fields"
                                    >

                                        <template slot="label" slot-scope="data">
                                            <b-button
                                                id="downloadLink"
                                                variant="link"
                                                @click="download(model, data.item.version)"
                                            >
                                                {{model + ' ' + data.item.version}}
                                            </b-button>

                                        </template>

                                        <template slot="action" slot-scope="row">
                                            <b-button-group>
                                                <b-button
                                                    class="actionBtns"
                                                    size="sm"
                                                    @click.stop="showDetailedInfoModal(row.item)"
                                                >
                                                    Detailed Info
                                                </b-button>

                                                <b-button
                                                    v-if='templateType === "modbus"'
                                                    v-b-popover.hover.bottom="'All UUIDs will be regenerated'"
                                                    title="Based on configuration of this model"
                                                    class="actionBtns"
                                                    size="sm"
                                                    variant="primary"
                                                    @click="()=>modifyDevice(model, row.item.version, true)"
                                                >
                                                    Create New Model
                                                </b-button>

                                                <b-button
                                                    v-if='templateType === "modbus"'
                                                    v-b-popover.hover.bottom="'All UUIDs are consistent '"
                                                    title="Based on configuration of this model"
                                                    class="actionBtns"
                                                    size="sm"
                                                    variant="success"
                                                    @click="()=>modifyDevice(model, row.item.version, false)"
                                                >
                                                    Create New Version
                                                </b-button>

                                                <b-button
                                                    class="actionBtns"
                                                    size="sm"
                                                    variant="danger" v-b-modal.modal-center.deleteDeviceModal
                                                    @click="()=> templateID = row.item.id"
                                                >
                                                    Delete
                                                </b-button>

                                            </b-button-group>
                                        </template>
                                    </b-table>
                                </b-tab>

                                <b-modal
                                    ref="detailedInfoModalRef"
                                    title="Detailed Information"
                                    size="lg"
                                    hide-footer 
                                >
                                    <b-container fluid>
                                        <b-table
                                            striped
                                            hover
                                            stacked="md"
                                            :items="items"
                                            :fields="['model', 'version', {'key':'uuid','label':'uuid'} , 'last update time']"
                                        />
                                    </b-container>
                                    
                                    <b-btn
                                        class="mt-3"
                                        variant="outline-danger"
                                        block
                                        @click="hideDetailedInfoModal"
                                    >
                                        Close Me
                                    </b-btn>
                                </b-modal>

                                <b-modal
                                    id="deleteDeviceModal"
                                    title="Confirm to delete?"
                                    @ok="deleteDevice"
                                    centered    
                                >
                                    Configuartions for this device will be discarded
                                </b-modal>

                                <!-- New Tab Button (Using tabs slot) -->
                                <b-nav-item
                                    v-if='templateType === "modbus"'
                                    slot="tabs"
                                >
                                    <b-btn
                                        v-b-popover.hover="'Create new model manually'"
                                        size="sm"
                                        variant="success"
                                        @click="()=>addDevice()"
                                    >
                                        Add device
                                    </b-btn>
                                </b-nav-item>
                                <!-- Render this if no tabs -->
                                <div slot="empty" class="text-center text-muted">
                                    There are no existing devices
                                    <br> Create a new device using Add Device button.
                                </div>
                            </b-tabs>
                        </b-card>

                        <p><i><b class="small">Click on model name to download encapsulated file</b></i></p>
                        <!-- tabs-vertical.vue -->
                    </b-col>
                </b-row>
            </b-container>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Devices',
    props: {},
    components: {},
    data() {
        return {
            tabs: [],

            isReady: false,
            fields: [
                'label',
                'version',
                'action'
            ],
            deviceModels: [],
            items: [],

            templateID: null,
            // templateType: 'modbus',
        }
    },
    computed: {
        templateType() {
            const path = this.$route.fullPath;

            if (path.includes('bacnet')) {
                return 'bacnet';
            }

            return 'modbus';
        },

        pageTitle() {
            if (this.templateType === 'modbus') {
                return 'Modbus Templates';
            }

            return 'BACnet Templates';
        },
    },
    watch: {
        'templateType': {
            handler: function () {
                this.updateTable();
            },
            immediate: true, //run it at initialization
        }
    },
    methods: {
        download(model, version) {
            var url = "api/def_file/" + this.templateType + "/?name=" + model + "&version=" + version
            window.location = url
        },

        addDevice() {
            this.$router.push({
                name: 'Device',
                params: {
                    'device': "new",
                    'isNewModel': true
                }
            })
        },

        showDetailedInfoModal(device) {
            this.items = [
                {
                    'model': device.name,
                    'version': device.version,
                    'uuid': device.uuid,
                    'last update time': device.last_updated_at
                }
            ]

            this.$refs.detailedInfoModalRef.show()
        },

        hideDetailedInfoModal() {
            this.$refs.detailedInfoModalRef.hide()
        },

        // isNewModel is a boolean input decide whether to keep model name and original uuid
        modifyDevice(model, version, isNewModel) {
            var fullName = model + '_' + version
            this.$router.push({
                name: 'Device',
                params: {
                    'device': fullName,
                    'isNewModel': isNewModel
                }
            })
        },

        deleteDevice() {
            this.request('delete', '/api/template/' + this.templateType + '/' + this.templateID)
            .then(response => {
                this.notify(response.data.message, "success");
                this.updateTable();
            })
        },

        updateTable() {
            this.isReady = false;
            this.request('get', '/api/template/' + this.templateType + '/')
            .then(response => {
                var deviceModels = {}
                    // var fields = {}
                    var devices = response.data
                    let device;
                    let modelName;

                    if (devices) {
                        var len = devices.length

                        for (var i = 0; i < len; i++) {
                            device = devices[i];
                            modelName = device.name;
                            
                            if (!deviceModels.hasOwnProperty(modelName)) {
                                deviceModels[modelName] = [];
                            }

                            deviceModels[modelName].push(device);
                        }
                    }

                    this.deviceModels = deviceModels;
                    this.isReady = true;
            })
        }
    },

    mounted() {}
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.spinner {
    width: 4rem;
    height: 4rem;
    position: fixed;
    top: 30%;
}

.template-list {
    width:100%;
    min-width: 964px;
    overflow: auto;
}

#deviceGroup {
    overflow: auto;
}

#downloadLink {
    text-decoration: none;
}

.actionBtns {
    padding-left: 5px;
}

#uploadBtn {
    float: right;
}

.my-tab {
    overflow: auto !important;
}
</style>