<template>
    <div id="blockTable">
        <b-jumbotron>
            <p class="title">Block Table</p>
        </b-jumbotron>

        <div id="blockTableContent">
            <!-- User Interface controls -->
            <b-row>
                <b-col md="4">
                    <b-form-group label-cols-sm="3" label="Filter" class="mb-0">
                        <b-input-group>
                            <b-form-input
                                v-model="filter"
                                placeholder="Type to Search"
                            />
                            <b-input-group-append>
                                <b-btn
                                    :disabled="!filter"
                                    @click="filter = ''"
                                >
                                    Clear
                                </b-btn>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group label-cols-sm="3" label="Sort" class="mb-0">
                        <b-input-group>
                            <b-form-select
                                v-model="sortBy"
                                :options="sortOptions"
                            >
                                <option slot="first" :value="null">-- none --</option>
                            </b-form-select>

                            <b-form-select
                                :disabled="!sortBy"
                                v-model="sortDesc"
                                slot="append"
                            >
                                <option :value="true">Desc</option>
                                <option :value="false">Asc</option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col md="4" class="my-1">
                    <b-form-group label-cols-sm="3" label="Per page" class="mb-0">
                        <b-form-select
                            :options="pageOptions"
                            v-model="perPage"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <!-- Main table element -->
            <b-table
                hover
                striped
                outlined
                footClone
                show-empty
                stacked="md"
                :items="blocks"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
            >
                <template slot="range" slot-scope="data">
                    {{generateBlockLabel(data.item.start, data.item.count, data.item.index)}}
                </template>

                <template slot="action" slot-scope="row">
                    <b-button
                        v-if='!isTypicalEnergyMeter'
                        size="sm"
                        @click.stop="row.toggleDetails"
                    >
                        {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
                    </b-button>
                    <edit-block-modal
                        :blockIndex="row.item.index"
                        :blockStartHex="row.item.startHex"
                        :blockCount="row.item.count"
                        :blockFunction="row.item.function"
                        @edit-block="editBlock"
                    />
                    <delete-block-modal
                        :blockIndex="row.item.index"
                        @delete-block="deleteBlock"
                    />
                </template>

                <template
                    v-if='!isTypicalEnergyMeter'
                    slot="row-details"
                    slot-scope="row"
                >
                    <point-table
                        id="pointTable"
                        :tabs="tabs"
                        :blocks="blocks"
                        :blockIndex="row.item.index"
                        :tabOptions="tabOptions"
                        :rawPoints="row.item.points"
                        :isTypicalEnergyMeter="isTypicalEnergyMeter"
                        @delete-point="deletePoint"
                        @edit-point="editPoint"
                    />
                </template>
            </b-table>

            <b-pagination
                class="my-0"
                v-model="currentPage"
                align="right"
                :total-rows="blocks.length"
                :per-page="perPage"
            />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import DeleteBlockModal from './deleteBlockModal.vue'
import EditBlockModal from './editBlockModal.vue'
import PointTable from './pointTable.vue'

export default {
    name: 'BlockTable',
    props: ['blocks', 'tabs', 'existingBlockNum', 'tabOptions', 'isTypicalEnergyMeter'],
    components: {
        'delete-block-modal': DeleteBlockModal,
        'edit-block-modal': EditBlockModal,
        'point-table': PointTable
    },
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            fields: [
                {
                    key: 'index',
                    label: 'Index',
                    sortable: true,
                    sortDirection: 'desc'
                },
                'startHex',
                {
                    key: 'start',
                    label: 'Start',
                    sortable: true
                },
                {
                    key: 'count',
                    label: 'Count',
                    sortable: true
                },
                'function',
                'range',
                'action'
            ],
            pointFields: ['label', 'address', 'dataType', 'slope', 'postLabel', 'cloudEnabled', 'units', {
                key: 'tab',
                label: ' Tab',
                variant: 'success'
            }],
            pageOptions: [5, 10, 15],
            filter: null,
            sortBy: 'index',
            sortDesc: true,
            sortDirection: 'desc'
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        }
    },
    watch: {},
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        deleteBlock(blockIndex) {
            this.$emit('delete-block', blockIndex)
        },

        deletePoint(pointIndex, tabLabel, blockIndex) {
            this.$emit('delete-point', pointIndex, tabLabel, blockIndex)
        },

        editBlock(blockIndex, startHex, count, func) {
            this.$emit('edit-block', blockIndex, startHex, count, func)
        },

        editPoint(corePointInfo, pointIndex, blockIndex) {
            this.$emit('edit-point', corePointInfo, pointIndex, blockIndex)
        }
    },

    mounted: function() {}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#blockTableContent {
    width: 90%;
    margin: 20px auto;
}

.jumbotron {
    padding: 5px 5px 1px 10px;
    text-align: left;
}

.title {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
}

</style>