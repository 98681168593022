export const paramList = {
      "Frequency": {
           "label":"Frequency",
           "postLabel":"Freq_Hz",
           "units":"Hz",
           "tab":"Basic Metering",
      },
      "Phase A Line-to-Neutral Voltage": {
           "label":"Phase A Line-to-Neutral Voltage",
           "postLabel":"Va_V",
           "units":"V",
           "tab":"Basic Metering",
      },
      "Phase B Line-to-Neutral Voltage": {
           "label":"Phase B Line-to-Neutral Voltage",
           "postLabel":"Vb_V",
           "units":"V",
           "tab":"Basic Metering",
      },
      "Phase C Line-to-Neutral Voltage": {
           "label":"Phase C Line-to-Neutral Voltage",
           "postLabel":"Vc_V",
           "units":"V",
           "tab":"Basic Metering",
      },
      "Phase A-B Line-to-Line Voltage": {
           "label":"Phase A-B Line-to-Line Voltage",
           "postLabel":"Vab_V",
           "units":"V",
           "tab":"Basic Metering",
      },
      "Phase B-C Line-to-Line Voltage": {
           "label":"Phase B-C Line-to-Line Voltage",
           "postLabel":"Vbc_V",
           "units":"V",
           "tab":"Basic Metering",
      },
      "Phase C-A Line-to-Line Voltage": {
           "label":"Phase C-A Line-to-Line Voltage",
           "postLabel":"Vca_V",
           "units":"V",
           "tab":"Basic Metering",
      },
      "Average Line-to-Line Voltage": {
           "label":"Average Line-to-Line Voltage",
           "postLabel":"Vlavg_V",
           "units":"V",
           "tab":"Basic Metering",
      },
      "Average Line-to-Neutral Voltage": {
           "label":"Average Line-to-Neutral Voltage",
           "postLabel":"Vnavg_V",
           "units":"V",
           "tab":"Basic Metering",
      },
      "Phase A Line Current": {
           "label":"Phase A Line Current",
           "postLabel":"Ia_A",
           "units":"A",
           "tab":"Basic Metering",
      },
      "Phase C Line Current": {
           "label":"Phase C Line Current",
           "postLabel":"Ib_A",
           "units":"A",
           "tab":"Basic Metering",
      },
      "Average Line Current": {
           "label":"Average Line Current",
           "postLabel":"Ic_A",
           "units":"A",
           "tab":"Basic Metering",
      },
      "Phase B Line Current": {
           "label":"Phase B Line Current",
           "postLabel":"Iavg_A",
           "units":"A",
           "tab":"Basic Metering",
      },
      "System Active Power": {
           "label":"System Active Power",
           "postLabel":"P_kW",
           "units":"kW",
           "tab":"Basic Metering",
      },
      "Phase A Active Power": {
           "label":"Phase A Active Power",
           "postLabel":"Pa_kW",
           "units":"kW",
           "tab":"Basic Metering",
      },
      "Phase B Active Power": {
           "label":"Phase B Active Power",
           "postLabel":"Pb_kW",
           "units":"kW",
           "tab":"Basic Metering",
      },
      "Phase C Active Power": {
           "label":"Phase C Active Power",
           "postLabel":"Pc_kW",
           "units":"kW",
           "tab":"Basic Metering",
      },
      "System Reactive Power": {
           "label":"System Reactive Power",
           "postLabel":"Q_kvar",
           "units":"kvar",
           "tab":"Basic Metering",
      },
      "Phase A Reactive Power": {
           "label":"Phase A Reactive Power",
           "postLabel":"Qa_kvar",
           "units":"kvar",
           "tab":"Basic Metering",
      },
      "Phase B Reactive Power": {
           "label":"Phase B Reactive Power",
           "postLabel":"Qb_kvar",
           "units":"kvar",
           "tab":"Basic Metering",
      },
      "Phase C Reactive Power": {
           "label":"Phase C Reactive Power",
           "postLabel":"Qc_kvar",
           "units":"kvar",
           "tab":"Basic Metering",
      },
      "System Apparent Power": {
           "label":"System Apparent Power",
           "postLabel":"S_kVA",
           "units":"kVA",
           "tab":"Basic Metering",
      },
      "Phase A Apparent Power": {
           "label":"Phase A Apparent Power",
           "postLabel":"Sa_kVA",
           "units":"kVA",
           "tab":"Basic Metering",
      },
      "Phase B Apparent Power": {
           "label":"Phase B Apparent Power",
           "postLabel":"Sb_kVA",
           "units":"kVA",
           "tab":"Basic Metering",
      },
      "Phase C Apparent Power": {
           "label":"Phase C Apparent Power",
           "postLabel":"Sc_kVA",
           "units":"kVA",
           "tab":"Basic Metering",
      },
      "System Power Factor": {
           "label":"System Power Factor",
           "postLabel":"PF",
           "units":"",
           "tab":"Basic Metering",
      },
      "Phase A Power Factor": {
           "label":"Phase A Power Factor",
           "postLabel":"PFa",
           "units":"",
           "tab":"Basic Metering",
      },
      "Phase B Power Factor": {
           "label":"Phase B Power Factor",
           "postLabel":"PFb",
           "units":"",
           "tab":"Basic Metering",
      },
      "Phase C Power Factor": {
           "label":"Phase C Power Factor",
           "postLabel":"PFc",
           "units":"",
           "tab":"Basic Metering",
      },
      "System Import Active Energy": {
           "label":"System Import Active Energy",
           "postLabel":"EP_IMP_kWh",
           "units":"kWh",
           "tab":"Energy",
      },
      "System Export Active Energy": {
           "label":"System Export Active Energy",
           "postLabel":"EP_EXP_kWh",
           "units":"kWh",
           "tab":"Energy",
      },
      "Phase A Import Active Energy": {
           "label":"Phase A Import Active Energy",
           "postLabel":"EPa_IMP_kWh",
           "units":"kWh",
           "tab":"Energy",
      },
      "Phase A Export Active Energy": {
           "label":"Phase A Export Active Energy",
           "postLabel":"EPa_EXP_kWh",
           "units":"kWh",
           "tab":"Energy",
      },
      "Phase B Import Active Energy": {
           "label":"Phase B Import Active Energy",
           "postLabel":"EPb_IMP_kWh",
           "units":"kWh",
           "tab":"Energy",
      },
      "Phase B Export Active Energy": {
           "label":"Phase B Export Active Energy",
           "postLabel":"EPb_EXP_kWh",
           "units":"kWh",
           "tab":"Energy",
      },
      "Phase C Import Active Energy": {
           "label":"Phase C Import Active Energy",
           "postLabel":"EPc_IMP_kWh",
           "units":"kWh",
           "tab":"Energy",
      },
      "Phase C Export Active Energy": {
           "label":"Phase C Export Active Energy",
           "postLabel":"EPc_EXP_kWh",
           "units":"kWh",
           "tab":"Energy",
      },
      "System Import Reactive Energy": {
           "label":"System Import Reactive Energy",
           "postLabel":"EQ_IMP_kvarh",
           "units":"kvarh",
           "tab":"Energy",
      },
      "System Export Reactive Energy": {
           "label":"System Export Reactive Energy",
           "postLabel":"EQ_EXP_kvarh",
           "units":"kvarh",
           "tab":"Energy",
      },
      "Phase A Import Reactive Energy": {
           "label":"Phase A Import Reactive Energy",
           "postLabel":"EQa_IMP_kvarh",
           "units":"kvarh",
           "tab":"Energy",
      },
      "Phase A Export Reactive Energy": {
           "label":"Phase A Export Reactive Energy",
           "postLabel":"EQa_EXP_kvarh",
           "units":"kvarh",
           "tab":"Energy",
      },
      "Phase B Import Reactive Energy": {
           "label":"Phase B Import Reactive Energy",
           "postLabel":"EQb_IMP_kvarh",
           "units":"kvarh",
           "tab":"Energy",
      },
      "Phase B Export Reactive Energy": {
           "label":"Phase B Export Reactive Energy",
           "postLabel":"EQb_EXP_kvarh",
           "units":"kvarh",
           "tab":"Energy",
      },
      "Phase C Import Reactive Energy": {
           "label":"Phase C Import Reactive Energy",
           "postLabel":"EQc_IMP_kvarh",
           "units":"kvarh",
           "tab":"Energy",
      },
      "Phase C Export Reactive Energy": {
           "label":"Phase C Export Reactive Energy",
           "postLabel":"EQc_EXP_kvarh",
           "units":"kvarh",
           "tab":"Energy",
      },
      "System Apparent Energy": {
           "label":"System Apparent Energy",
           "postLabel":"ES_kVAh",
           "units":"kVAh",
           "tab":"Energy",
      },
      "Phase A Apparent Energy": {
           "label":"Phase A Apparent Energy",
           "postLabel":"ESa_kVAh",
           "units":"kVAh",
           "tab":"Energy",
      },
      "Phase B Apparent Energy": {
           "label":"Phase B Apparent Energy",
           "postLabel":"ESb_kVAh",
           "units":"kVAh",
           "tab":"Energy",
      },
      "Phase C Apparent Energy": {
           "label":"Phase C Apparent Energy",
           "postLabel":"ESc_kVAh",
           "units":"kVAh",
           "tab":"Energy",
      },
      "System Active Power Demand": {
           "label":"System Active Power Demand",
           "postLabel":"DMD_P_kW",
           "units":"kW",
           "tab":"Demand",
      },
      "Phase A Active Power Demand": {
           "label":"Phase A Active Power Demand",
           "postLabel":"DMD_Pa_kW",
           "units":"kW",
           "tab":"Demand",
      },
      "Phase B Active Power Demand": {
           "label":"Phase B Active Power Demand",
           "postLabel":"DMD_Pb_kW",
           "units":"kW",
           "tab":"Demand",
      },
      "Phase C Active Power Demand": {
           "label":"Phase C Active Power Demand",
           "postLabel":"DMD_Pc_kW",
           "units":"kW",
           "tab":"Demand",
      },
      "System Reactive Power Demand": {
           "label":"System Reactive Power Demand",
           "postLabel":"DMD_Q_kvar",
           "units":"kvar",
           "tab":"Demand",
      },
      "Phase A Reactive Power Demand": {
           "label":"Phase A Reactive Power Demand",
           "postLabel":"DMD_Qa_kvar",
           "units":"kvar",
           "tab":"Demand",
      },
      "Phase B Reactive Power Demand": {
           "label":"Phase B Reactive Power Demand",
           "postLabel":"DMD_Qb_kvar",
           "units":"kvar",
           "tab":"Demand",
      },
      "Phase C Reactive Power Demand": {
           "label":"Phase C Reactive Power Demand",
           "postLabel":"DMD_Qc_kvar",
           "units":"kvar",
           "tab":"Demand",
      },
      "System Apparent Power Demand": {
           "label":"System Apparent Power Demand",
           "postLabel":"DMD_S_kVA",
           "units":"kVA",
           "tab":"Demand",
      },
      "Phase A Apparent Power Demand": {
           "label":"Phase A Apparent Power Demand",
           "postLabel":"DMD_Sa_kVA",
           "units":"kVA",
           "tab":"Demand",
      },
      "Phase B Apparent Power Demand": {
           "label":"Phase B Apparent Power Demand",
           "postLabel":"DMD_Sb_kVA",
           "units":"kVA",
           "tab":"Demand",
      },
      "Phase C Apparent Power Demand": {
           "label":"Phase C Apparent Power Demand",
           "postLabel":"DMD_Sc_kVA",
           "units":"kVA",
           "tab":"Demand",
      }
}
