<template>
    <span id="editTypicalParamModal">
        <b-modal
            ref="editModal"
            title="Edit this parameter"
            :ok-disabled="!isParamValid"
            @ok="editTypicalParam"
            centered  
        >
            <b-form>
                <b-form-group
                    id="labelGroup"
                    label="Label:"
                    label-for="labelInput"
                    description="i.e., 'Phase A Voltage THD'"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="labelInput"
                        v-model="point.label"
                        placeholder="Enter label"
                        type="text"
                        readonly
                        required
                    />
                </b-form-group>
                
                <b-form-group
                    id="postLabelGroup"
                    label="Post Label:"
                    label-for="postLabelInput"
                    description="i.e., 'THD_Va'"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="postLabelInput"
                        type="text"
                        v-model="point.postLabel"
                        placeholder="Enter postLabel"
                        readonly
                        required
                    />
                </b-form-group>
                
                <b-form-group
                    id="unitsGroup"
                    label="Unit (optional):"
                    label-for="unitsInput"
                    description="e.g. % & kvarh"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="unitsInput"
                        v-model="point.units"
                        type="text"
                        readonly
                        placeholder="N/A"
                    />
                </b-form-group>

                <b-form-group
                    id="tabGroup"
                    label="Tab:"
                    label-for="tabSelect"
                    description=""
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="tabSelect"
                        v-model="point.tab"
                        placeholder="Enter tab"
                        type="text"
                        readonly
                        required
                    />
                </b-form-group>

                <b-form-group
                    id="blockGroup"
                    label="Block:"
                    label-for="blockSelect"
                    description="Create new block if none exists"
                    label-cols-sm="3"
                    
                >
                    <b-form-select
                        id="blockSelect"
                        v-model="blockLabel"
                        :options="blockOptions"
                        required   
                    >
                        <template slot="first">
                            <!-- this slot appears above the options from 'options' prop -->
                            <option
                                :value="null"
                                disabled
                            >
                                -- Please select an option --
                            </option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <b-form-group
                    id="dataFormatGroup"
                    label="Data Format:"
                    label-for="dataFormatInput"
                    description=""
                    label-cols-sm="3"
                >
                    <b-form-select
                        id="dataFormatInput"
                        v-model="dataFormat"
                        :options="dataFormatOptions"
                        :disabled="isDataTypeFixed"
                        required  
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                -- Please select an option --
                            </option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <b-form-group
                    id="byteOrderGroup"
                    label="Byte Order:"
                    label-for="byteOrderInput"
                    description=""
                    label-cols-sm="3"
                >
                    <b-form-select
                        id="byteOrderInput"
                        v-model="byteOrder"
                        :options="byteOrderOptions"
                        :disabled="isDataTypeFixed"
                        required  
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                -- Please select an option --
                            </option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <b-form-group
                    id="dataTypeGroup"
                    label="Data Type:"
                    label-for="dataTypeInput"
                    description="Select data format and byte order to determine data type"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="dataTypeInput"
                        number
                        v-model="dataType"
                        placeholder=""
                        readonly
                    />
                </b-form-group>

                <b-form-group
                    id="addressGroup"
                    label="Address:"
                    label-for="hexAddress"
                    description=""
                    label-cols-sm="3"
                >
                    <div class="hexAddress">
                        <label>0x</label>
                        <the-mask
                            class="hexInput"
                            v-model="addressHex"
                            mask="FFFFFF"
                            :tokens="hexToken"
                        />
                    </div>
                </b-form-group>

                <b-form-group
                    id="slopeGroup"
                    label="Slope:"
                    label-for="slopeInput"
                    description="i.e., 0.1"
                    label-cols-sm="3"
                >
                    <b-form-input
                        id="slopeInput"
                        number
                        v-model="slope"
                        placeholder="Enter slope"
                        required
                    />
                </b-form-group>

                <b-form-group
                    id="cloudEnabledGroup"
                    label="Cloud Enabled:"
                    label-cols-sm="3" 
                >
                    <b-form-radio-group
                        id="cloudEnabledChecks"
                        v-model="cloudEnabled"
                        stacked
                    >
                        <b-form-radio :value="true">True</b-form-radio>
                        <b-form-radio :value="false">False</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>

            </b-form>
        </b-modal>

        <b-button
            class="editBtn"
            size="sm"
            variant="primary"
            @click.stop="showModal"
        >
            Edit
        </b-button>
    </span>
</template>

<script>
import {
    dataFormatOptions,
    byteOrderOptions,
    cloudEnabledOptions,
} from '../fixtures/paramOptions';
import { MBT_DATA_TYPE_MAGIC_NUMBER, hexToken } from '../fixtures/constant';

export default {
    name: 'EditTypicalParamModal',
    props: {
        'point': Object,
        'blocks': Array,
        'blockOptions': Array,
        'paramAddressHex': {
            type: String,
            default: function () {
                return '0x'
            }
        }
    },
    data() {
        return {
            dataFormatOptions,
            byteOrderOptions,
            cloudEnabledOptions,
            hexToken,

            blockLabel: this.point.block,
            dataFormat: null,
            byteOrder: null,
            dataType: this.point.dataType,
            addressHex: this.paramAddressHex.replace('0x', ''),
            slope: this.point.slope,
            cloudEnabled: this.point.cloudEnabled || true,
        }
    },
    components: {
    },
    computed: {
        isParamValid() {
            return this.blockLabel !== undefined && this.dataType !== undefined && this.addressHex !== "" && this.slope !== undefined
        },

        isDataTypeFixed() {
            if (this.blockLabel) {
                const firstColonIndex = this.blockLabel.indexOf(":");
                const selectedBlockIndex = Number(this.blockLabel.slice(6, firstColonIndex - 1));
                const blockFunction = this.blocks[selectedBlockIndex].function;

                if (blockFunction === 'READ_COILS' || blockFunction === 'READ_DISCRETE_INPUTS') {
                    if (this.dataFormat !== 0 || this.byteOrder !== 0) {
                        // this.$notify.info({
                        //     content: 'Data format would be "UINT16" and Byte Order would be "NORMAL" for this block',
                        //     placement: 'top-center',
                        //     duration: 5000,
                        //     closable: true,
                        // })

                        // eslint-disable-next-line
                        this.dataFormat = 0;
                        // eslint-disable-next-line
                        this.byteOrder = 0;
                    }

                    return true;
                }
            }

            return false;
        },
    },
    watch: {
        'paramAddressHex': function(newVal) {
            this.addressHex = newVal.replace('0x', '');
        },

        'point.dataType': function(newVal) {
            this.dataType = newVal;
        },

        'point.block': function(newVal) {
            this.blockLabel = newVal;
        },

        'point.slope': function(newVal) {
            this.slope = newVal;
        },

        'point.cloudEnabled': function(newVal) {
            this.cloudEnabled = newVal || false;
        },

        byteOrder: function() {
            if (this.byteOrder === null || this.dataFormat === null) {
                return null
            }
            else {
                this.dataType = this.byteOrder * MBT_DATA_TYPE_MAGIC_NUMBER + this.dataFormat
            }
        },

        dataFormat: function() {
            if (this.byteOrder === null || this.dataFormat === null) {
                return null
            }
            else {
                this.dataType = this.byteOrder * MBT_DATA_TYPE_MAGIC_NUMBER + this.dataFormat
            }
        },
    },
    methods: {
        showModal() {
            this.$refs.editModal.show()
        },

        editTypicalParam() {
            const newParam = {
                id: this.point.id,
                index: this.point.index,
                label: this.point.label,
                postLabel: this.point.postLabel,
                units: this.point.units,
                tab: this.point.tab,
                addressHex: '0x' + this.addressHex,
                dataType: this.dataType,
                slope: this.slope,
                cloudEnabled: this.cloudEnabled,
                block: this.blockLabel,
            }
            
            this.$emit('edit-typical-param', newParam);

            // reset data type
            this.dataFormat = null;
            this.byteOrder = null;
        }
            
    },

    mounted() {
    }
}
</script>

<style scoped>
#editTypicalParamModal {
    display: inline-block;
    margin-left: 2px;
}

.editBtn {
    width: 52px;
}
</style>