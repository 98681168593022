<template>
    <b-container fluid class="pointContainer">
        <!-- User Interface controls -->
        <b-row v-if="points.length>0">
            <b-col md="4">
                <b-form-group label-cols-sm="3" label="Filter" class="mb-0">
                    <b-input-group>
                        <b-form-input
                            v-model="filter"
                            placeholder="Type to Search"
                        />

                        <b-input-group-append>
                            <b-btn
                                :disabled="!filter"
                                @click="filter = ''"
                            >
                                Clear
                            </b-btn>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col md="4">
                <b-form-group label-cols-sm="3" label="Sort" class="mb-0">
                    <b-input-group>
                        <b-form-select
                            v-model="sortBy"
                            :options="sortOptions"
                        >
                            <option slot="first" :value="null">-- none --</option>
                        </b-form-select>

                        <b-form-select
                            :disabled="!sortBy"
                            v-model="sortDesc"
                            slot="append"
                        >
                            <option :value="true">Desc</option>
                            <option :value="false">Asc</option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col md="4">
                <b-form-group label-cols-sm="3" label="Per page" class="mb-0">
                    <b-form-select
                        :options="pageOptions"
                        v-model="perPage"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <!-- Main table element -->
        <b-table
            hover
            small
            striped
            footClone
            show-empty
            stacked="md"
            :items="points"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
        >
            <template slot="action" slot-scope="row">
                <edit-point-modal
                    :point="row.item"
                    :blockFunction="blocks[blockIndex].function"
                    :tabOptions="tabOptions"
                    @edit-point="editPoint"
                />
                <delete-point-modal
                    :pointIndex="row.item.index"
                    :tabLabel="row.item.tab"
                    @delete-point="deletePoint"
                />
            </template>
        </b-table>

        <b-pagination
            v-if="points.length>0"
            class="my-0"
            v-model="currentPage"
            :total-rows="points.length"
            :per-page="perPage"  
        />
    </b-container>
</template>
<script>
/* eslint-disable */
import DeletePointModal from './deletePointModal.vue'
import EditPointModal from './editPointModal.vue'
var converter = require('hex2dec')

export default {
    name: 'PointTable',
    props: ['tabs', 'rawPoints', 'blockIndex', 'blocks', 'tabOptions'],
    components: {
        'delete-point-modal': DeletePointModal,
        'edit-point-modal': EditPointModal,
    },
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            // points: [],
            fields: [
                {
                    key: 'index',
                    label: 'Index',
                    sortable: true,
                    sortDirection: 'desc'
                },
                {
                    key: 'tab',
                    label: ' Tab',
                    variant: 'success'
                },
                'label',
                'addressHex',
                {
                    key: 'address',
                    label: 'Address',
                    sortable: true,
                    sortDirection: 'desc'
                },
                'dataType',
                'slope',
                'postLabel',
                'cloudEnabled',
                'units',
                'action'
            ],
            pageOptions: [5, 10, 15, 25, 50, 100],
            filter: null,
            sortBy: 'index',
            sortDesc: true,
            sortDirection: 'desc',
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        },

        points() {
            return this.generatePoints(this.rawPoints)
        }
    },
    watch: {},
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        generatePoints(points) {
            var pointNum = points.length
            var tabNum = this.tabs.length

            for (var i = 0; i < pointNum; i++) {
                var point = points[i]
                point.tab = ''

                for (var j = 0; j < tabNum; j++) {
                    var currentTab = this.tabs[j]
                    if (currentTab.params.includes(point.id)) {
                        if (point.tab !== '') {
                            point.tab = point.tab + '/' + currentTab.label
                        } else {
                            point.tab = point.tab + currentTab.label
                        }
                    }
                }
            }
            return points
        },

        deletePoint(pointIndex, tabLabel) {
            this.$emit('delete-point', pointIndex, tabLabel, this.blockIndex)
        },

        editPoint(corePointInfo, pointIndex) {
            this.$emit('edit-point', corePointInfo, pointIndex, this.blockIndex)
        }

    },

    mounted: function() {
        // this.points = this.generatePoints(this.rawPoints)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pointContainer {
    background-color: #e5ece5;
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>