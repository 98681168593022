<template>
    <span id="deleteTypicalParamModal">
        <b-modal
            ref="deleteModal"
            title="Are you sure to delete this parameter?"
            @ok="deleteTypicalParam"
        >
            This parameter will be removed 
        </b-modal>

        <b-button
            size="sm"
            variant="danger"
            @click.stop="showModal"
        >
            Delete
        </b-button>
    </span>
</template>

<script>
export default {
    name: 'DeletePointModal',
    data() {
        return {}
    },
    props: ['pointIndex', 'rowIndex', 'tab', 'block'],
    methods: {
        showModal() {
            this.$refs.deleteModal.show()
        },

        deleteTypicalParam() {
            this.$emit('delete-typical-param', this.pointIndex, this.tab, this.block, this.rowIndex);
        }
    },

    mounted() {}
}
</script>

<style>
#deleteTypicalParamModal {
    display: inline-block;
    margin-left: 2px;
}

</style>