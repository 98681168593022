<template>
    <div>
        <b-spinner
            class="spinner"
            v-if="isLoading"
            variant="secondary"
        />
        <transition name="fade">
            <b-container fluid v-if="!isLoading">
                <!-- User Interface controls -->
                <b-row>
                    <b-col xl="9">
                        <b-jumbotron>
                            <p class="title">Device Template</p>
                        </b-jumbotron>
                        <center style="width: 90%; margin: auto;">
                            <steps
                                v-if='!isTypicalEnergyMeter'
                                id="steps"
                                :current="current"
                                :show-footer="false"
                                @onManualSwitch="changeStep"
                                type="line"
                                style="width: 80%;"
                            >
                                <step title="Device" description="">
                                    <device-module
                                        ref="deviceModule"
                                        :originalLabel="modelForm.label"
                                        :originalCloudModel="modelForm.meta.classes[0].label"
                                        :originalIsOfficial="modelForm.official"
                                        :deviceInfo="modelForm.meta.deviceInfo"
                                        :isNewModel="isNewModel"
                                        :isTypicalModel="isTypicalEnergyMeter"
                                        @save="saveDevice"
                                    />

                                     <scroll-to target="#steps">
                                        <b-button
                                            class="stepBtn"
                                            @click="handleNext"
                                        >
                                            <span>Next</span>
                                            <span class="icon is-small" style="vertical-align: text-bottom;">
                                                <i class="fa fa-angle-right"></i>
                                            </span>
                                        </b-button>
                                    </scroll-to>
                                </step>

                                <step title="Block" description="">
                                    <block-module
                                        ref="blockModule"
                                        :blockOptions="blockOptions"
                                        @save="saveBlock"
                                    />

                                    <scroll-to target="#steps">
                                        <b-button
                                            class="stepBtn"
                                            @click="handleNext"
                                        >
                                            <span>Next</span>
                                            <span class="icon is-small" style="vertical-align: text-bottom;">
                                                <i class="fa fa-angle-right"></i>
                                            </span>
                                        </b-button>
                                        
                                        <b-button
                                            class="stepBtn"
                                            @click="handlePrev"
                                        >
                                            <span class="icon is-small" style="vertical-align: text-bottom;">
                                                <i class="fa fa-angle-left"></i>
                                            </span>
                                            <span>Previous</span>
                                        </b-button>
                                    </scroll-to>
                                </step>

                                <step title="Parameter" description="">
                                    <point-module
                                        ref="pointModule"
                                        :blocks="modelForm.meta.blocks"
                                        :blockOptions="blockOptions"
                                        :tabOptions="tabOptions"
                                        :paramOptions="paramOptions"
                                        @save-tab="saveTab"
                                        @save-point="savePoint"
                                    />

                                     <scroll-to target="#steps">
                                        <b-button
                                            class="stepBtn"
                                            @click="handleNext"
                                        >
                                            <span>Next</span>
                                            <span class="icon is-small" style="vertical-align: text-bottom;">
                                                <i class="fa fa-angle-right"></i>
                                            </span>
                                        </b-button>
                                        
                                        <b-button
                                            class="stepBtn"
                                            @click="handlePrev"
                                        >
                                            <span class="icon is-small" style="vertical-align: text-bottom;">
                                                <i class="fa fa-angle-left"></i>
                                            </span>
                                            <span>Previous</span>
                                        </b-button>
                                    </scroll-to>
                                </step>

                                <step title="Save" description="">
                                    <save-module
                                        @save="saveForm"
                                    />

                                     <scroll-to target="#steps">
                                        <b-button
                                            class="stepBtn"
                                            @click="handlePrev"
                                        >
                                            <span class="icon is-small" style="vertical-align: text-bottom;">
                                                <i class="fa fa-angle-left"></i>
                                            </span>
                                            <span>Previous</span>
                                        </b-button>
                                    </scroll-to>
                                </step>
                            </steps>

                            <div v-if='isTypicalEnergyMeter'>
                                <device-module
                                    ref="deviceModule"
                                    :originalLabel="modelForm.label"
                                    :originalCloudModel="modelForm.meta.classes[0].label"
                                    :originalIsOfficial="modelForm.official"
                                    :deviceInfo="modelForm.meta.deviceInfo"
                                    :isNewModel="isNewModel"
                                    :isTypicalModel="isTypicalEnergyMeter"
                                    @save="saveDevice"
                                />
                                <br>
                                <block-module
                                    ref="blockModule"
                                    :blockOptions="blockOptions"
                                    @save="saveBlock"
                                />
                                <save-module
                                    @save="saveForm"
                                />
                                <br />
                            </div>
                        </center>
                        
                        <block-table
                            :blocks="modelForm.meta.blocks"
                            :tabs="modelForm.meta.tabs"
                            :tabOptions="tabOptions"
                            :existing-block-num="initialBlockFormLen"
                            :isTypicalEnergyMeter="isTypicalEnergyMeter"
                            @edit-block="editBlock"
                            @edit-point="editPoint"
                            @delete-block="deleteBlock"
                            @delete-point="deletePoint"
                        />
                        <typical-param-table
                            v-if="isTypicalEnergyMeter"
                            ref="paramTable"
                            :blocks="modelForm.meta.blocks"
                            :blockOptions="blockOptions"
                            @edit-typical-param="editTypicalParam"
                            @delete-typical-param="deleteTypicalParam"
                        />
                    </b-col>

                    <b-col xl="3" class="my-1">
                        <tree-view id="treeViewer" :data="modelForm"></tree-view>
                    </b-col>
                </b-row>
            </b-container>
        </transition>
    </div>
</template>
<script>
/* eslint-disable */
import DeviceModule from './stepComponents/deviceModule.vue'
import BlockModule from './stepComponents/blockModule.vue'
import PointModule from './stepComponents/pointModule.vue'
import SaveModule from './stepComponents/saveModule.vue'
import BlockTable from './forms/blockTable.vue'
import TypicalParamTable from './forms/typicalParamTable.vue'
import steps  from './steps/Steps'
import step  from './steps/Step'

var converter = require('hex2dec')
// var hex = converter.hexToDec('0xFA'); // 250
// var dec = converter.decToHex('250'); // '0xfa'
export default {
    name: 'Parameter',
    components: {
        'block-table': BlockTable,
        'device-module': DeviceModule,
        'block-module': BlockModule,
        'point-module': PointModule,
        'save-module': SaveModule,
        'typical-param-table': TypicalParamTable,
        'steps': steps,
        'step': step, 
    },
    data() {
        return {
            isLoading: true,
            isSaved: false,

            showCollapse: true,
            current: 0,

            isNewModel: true,
            isTypicalEnergyMeter: false,
            // selectDevice: 'create',

            modelForm: {
                uuid: this.generateUUID(),
                label: '',
                version: '',
                official: true,
                last_updated_at: '',
                meta: {
                    tabs: [],
                    blocks: [],
                    classes: [{
                        label: '', // To be used in AcuCloud, it must be a model defined in AcuCloud, e.g., "Acuvim II", "AcuRev 2020-18 Channel", etc.
                        models: [ // suggest using one model only
                            {
                                label: '', // equals to modelForm.label
                                meta: {
                                    protocols: {
                                        modbus: {
                                            tcp: true,
                                            rtu: true
                                        }
                                    }
                                }
                            }
                        ]
                    }],
                    deviceInfo: []
                }
            },

            initialModelForm: {},

            blockFormLen: 0,
            initialBlockFormLen: 0,

            blockOptions: [],
            tabOptions: [],
            paramOptions: []
        }
    },
    computed: {},
    watch: {},
    methods: {
        handleNext() {
            this.current < 3 && this.current++;
        },

        handlePrev() {
            this.current >= 0 && this.current--;
        },

        changeStep(step) {
            this.current = step;
        },

        getExistingForm(model, version, isNewModel) {
            var url = '/api/json/modbus?name=' + model + '&version=' + version
            this.request('get', '/api/json/modbus?name=' + model + '&version=' + version)
            .then(response => {
                let modelForm = response.data
                if (modelForm.meta.deviceInfo === undefined) {
                    modelForm.meta.deviceInfo = [];
                }
                else {
                    // convert decimal address to hex address without '0x'
                    modelForm.meta.deviceInfo.forEach(info => {
                        info.address = converter.decToHex(String(info.address)).slice(2);
                    });
                }

                if (modelForm.meta.classes === undefined) {
                    modelForm.meta.classes = [{
                        label: '', // To be used in AcuCloud, it must be a model defined in AcuCloud, e.g., "Acuvim II", "AcuRev 2020-18 Channel", etc.
                        models: [ // suggest using one model only
                            {
                                label: '', // equals to modelForm.label
                                meta: {
                                    protocols: {
                                        modbus: {
                                            tcp: true,
                                            rtu: true
                                        }
                                    }
                                }
                            }
                        ]
                    }]
                }

                if (modelForm.meta.classes[0].label === 'Typical Energy Meter') {
                    this.isTypicalEnergyMeter = true;
                }

                // reset some fields
                modelForm.version = ''

                if (isNewModel === true) {
                    modelForm.uuid = this.generateUUID()
                    modelForm.label = ''

                    if (modelForm.meta.classes[0].label !== 'Typical Energy Meter') {
                        modelForm.meta.classes[0].label = '';
                    }
                    
                    modelForm.meta.classes[0].models[0].label = ''

                    // replace all existing uuids
                    // change existing ids of tabs
                    modelForm.meta.tabs.forEach(tab => {
                        tab.id = this.generateUUID()
                    })
                    modelForm.meta.blocks.forEach(block => {
                        // change existing ids of blocks
                        block.id = this.generateUUID()

                        // change existing ids of parameters
                        block.points.forEach(point => {
                            var originalPointID = point.id
                            var newPointID = this.generateUUID()
                            point.id = newPointID

                            // sync ids of parameters stored in tabs
                            modelForm.meta.tabs.forEach(tab => {
                                let paramIndex = tab.params.indexOf(originalPointID)
                                if (paramIndex !== -1) {
                                    tab.params.splice(paramIndex, 1, newPointID)
                                }
                            })
                        })
                    })
                }

                var tabs = response.data.meta.tabs
                var tabLen = tabs.length
                for (var i = 0; i < tabLen; i++) {
                    // add tab to the beginning of tabOptions to order desc
                    this.tabOptions.unshift(tabs[i].label)
                }

                var blocks = response.data.meta.blocks
                var blockLen = blocks.length
                // save existing number of blocks
                this.blockFormLen = blockLen
                this.initialBlockFormLen = blockLen

                for (var i = 0; i < blockLen; i++) {
                    var currentBlock = blocks[i]
                    // create new fields "index" and "startHex"
                    // remember to delete them before saving to database
                    currentBlock.index = i
                    var startHex = converter.decToHex(String(currentBlock.start))
                    // bug of converter: return null for decimal 0 rather than 0x0
                    if (startHex === null) {
                        startHex = '0x0'
                    }
                    currentBlock.startHex = '0x' + startHex.slice(2).toLocaleUpperCase()
                    var blockLabel = this.generateBlockLabel(currentBlock.start, currentBlock.count, i)
                    // add blcok to the beginning of blockOptions to order desc
                    this.blockOptions.unshift(blockLabel)

                    // create new field "index" for points
                    var currentPoints = currentBlock.points
                    var pointNum = currentPoints.length
                    for (var j = 0; j < pointNum; j++) {
                        currentPoints[j].index = j
                        var addressHex = converter.decToHex(String(currentPoints[j].address))

                        // bug of converter: return null for decimal 0
                        if (addressHex === null) {
                            addressHex = '0x0'
                        }
                        currentPoints[j].addressHex = addressHex
                    }
                }

                this.modelForm = modelForm;
                this.initialModelForm = JSON.parse(JSON.stringify(modelForm));
                this.isLoading = false;
            })
        },

        saveDevice(label, version, cloudModel, isOfficial, deviceInfo) {
            this.modelForm.label = label;
            this.modelForm.version = version;
            this.modelForm.official = isOfficial;
            this.modelForm.meta.classes[0].label = cloudModel;
            this.modelForm.meta.classes[0].models[0].label = label;

            if (deviceInfo && deviceInfo.length > 0) {
                this.modelForm.meta.deviceInfo = deviceInfo;
            }
            else {
                this.modelForm.meta.deviceInfo = [];
                
            }

            this.current = 1;
            this.notify("Device template has been initialized", "success");
        },

        saveBlock(blockForm, blockLabel) {
            this.blockOptions.unshift(blockLabel)
            // add index to this block
            blockForm.index = this.blockFormLen
            this.modelForm.meta.blocks.push(blockForm)
            this.blockFormLen++
        },

        saveTab(tabForm, tabLabel) {
            this.tabOptions.unshift(tabLabel)
            this.modelForm.meta.tabs.push(tabForm)
        },

        savePoint(paramForm, paramID, tabLabels, blockIndex) {
            this.paramOptions.unshift(paramID)
            // if one or more tabs are selected for this point
            if (tabLabels.length !== 0) {
                var tabs = this.modelForm.meta.tabs
                var tabNum = tabs.length
                for (var i = 0; i < tabNum; i++) {
                    if (tabLabels.indexOf(tabs[i].label) !== -1) {
                        this.modelForm.meta.tabs[i].params.push(paramID)
                    }
                }
            }

            var block = this.modelForm.meta.blocks[blockIndex]
            paramForm.index = block.points.length
            block.points.push(paramForm)
        },

        editBlock(blockIndex, startHex, count, func) {
            let block = this.modelForm.meta.blocks[blockIndex];

            block.startHex = String(startHex);
            block.start = Number(converter.hexToDec(startHex));
            block.count = Number(count);
            block.function = func;

            // blockOptions is ordered desc
            const newBlockLabel = this.generateBlockLabel(block.start, block.count, blockIndex);
            
            this.blockOptions.splice(this.blockFormLen - 1 - blockIndex, 1, newBlockLabel);
            this.notify("Block " + blockIndex + " has been updated!", "success");
        },

        deleteBlock(blockIndex) {
            // change indexs of all blocks behind this
            for (var i = blockIndex + 1; i < this.blockFormLen; i++) {
                this.modelForm.meta.blocks[i].index--
            }

            // remove all stored points from tabs
            let points = this.modelForm.meta.blocks[blockIndex].points
            points.forEach(point => {
                // sync ids of parameters stored in tabs
                this.modelForm.meta.tabs.forEach(tab => {
                    let paramIndex = tab.params.indexOf(point.id)
                    
                    if (paramIndex !== -1) {
                        tab.params.splice(paramIndex, 1)
                    }
                })
            })

            // remove element from array based on index
            this.modelForm.meta.blocks.splice(blockIndex, 1)

            // blockOptions is ordered desc
            this.blockOptions.splice(this.blockFormLen - 1 - blockIndex, 1)

            // change indexs of all block options behind this
            for (let i = 0; i < this.blockOptions.length - blockIndex; i++) {
                let blockLabel = this.blockOptions[i];
                let firstColonIndex = blockLabel.indexOf(":");
                let index =  blockLabel.slice(0, firstColonIndex).replace(/[^0-9]/g,'');
                
                this.blockOptions[i] = blockLabel.replace(index, Number(index) - 1);        
            }

            this.blockFormLen -= 1;

            if (this.isTypicalEnergyMeter) {
                this.$refs.paramTable.updatePoints();
            }
            
            this.notify("Block " + blockIndex + " has been removed!", "success");
        },

        initializeNewPoint(param) {
            const newPoint = {
                unsaved: true,
                id: this.generateUUID(),
                label: param.label,
                address: Number(converter.hexToDec(param.addressHex)),
                addressHex: param.addressHex,
                dataType: Number(param.dataType),
                slope: Number(param.slope),
                postLabel: param.postLabel,
                cloudEnabled: param.cloudEnabled,
                slopeEnabled: true,
                units: param.units,
                canEditUnits: null,
                canEditName: null,
                offsetEnabled: null,
                offset: null,
                multiplierRegisterEnabled: null,
                multiplierRegister: null,
                userScaleEnabled: null,
                invalidValueEnabled: null,
                invalidValue: null
            };

            return newPoint;
        },

        addPointToBlock(blockIndex, point) {
            let block = this.modelForm.meta.blocks[blockIndex];

            point.index = block.points.length;
            block.points.push(point);
        },

        addPointToTab(tabLabel, pointId) {
            if (!tabLabel || !pointId) {
                return;
            }

            let tabs = this.modelForm.meta.tabs;
            let tabNum = tabs.length;
            let tab = tabs.filter(tab => tab.label === tabLabel)[0];

            // create this tab if it does not exist
            if (!tab) {
                const newTab = {
                    id: this.generateUUID(),
                    label: tabLabel,
                    params: [pointId]
                };

                tabs.push(newTab);
            }
            // add the point to tabs
            else {
                tab.params.push(pointId)
            }
        },

        editTypicalParam(param, originalBlock) {
            const newBlock = param.block;
            const newBlockIndex = Number(newBlock.slice(5, newBlock.indexOf(':')));

            // add new point
            if (!originalBlock) {
                const newPoint = this.initializeNewPoint(param);
                // add target point to new block
                this.addPointToBlock(newBlockIndex, newPoint);
                // add target point to new tab
                this.addPointToTab(param.tab, newPoint.id);
            }
            // edit existing point
            else {
                const pointIndex = param.index;
                const blockIndex = Number(originalBlock.slice(5, originalBlock.indexOf(':')));

                let currentBlock = this.modelForm.meta.blocks[blockIndex];
                let point = currentBlock.points[pointIndex];

                // update point
                point.dataType = Number(param.dataType);
                point.slope = Number(param.slope);
                point.cloudEnabled = param.cloudEnabled;
                point.addressHex = String(param.addressHex);
                point.address = Number(converter.hexToDec(param.addressHex));
                point.unsaved = true;

                // Vue.set(this.modelForm.meta.blocks[blockIndex].points, pointIndex, point);

                // change block
                if (blockIndex !== newBlockIndex) {
                    let pointNum = currentBlock.points.length;

                    // change all index of points after this point
                    for (let i = pointIndex + 1; i < pointNum; i++) {
                        currentBlock.points[i].index--
                    }
                    // remove target point from this block
                    currentBlock.points.splice(pointIndex, 1);
                    // Vue.delete(this.modelForm.meta.blocks[blockIndex].points, pointIndex);

                    // add target point to new block
                    let newBlock = this.modelForm.meta.blocks[newBlockIndex];

                    point.index = newBlock.points.length;
                    // Vue.set(this.modelForm.meta.blocks[newBlockIndex].points, point.index, point);
                    newBlock.points.push(point);
                }
            }

            this.$refs.paramTable.updatePoints();
            this.notify("Parameter : " + param.label + " has been updated!", "success");
        },

        deleteTypicalParam(pointIndex, tab, block) {
            const blockIndex = Number(block.slice(5, block.indexOf(':')));
            let currentBlock = this.modelForm.meta.blocks[blockIndex];
            const point = currentBlock.points[pointIndex];
            const paramId = point.id;
            const pointNum = currentBlock.points.length;
            const tabs = this.modelForm.meta.tabs;
            const tabNum = tabs.length;

            // change all index of points after this point
            for (var i = pointIndex + 1; i < pointNum; i++) {
                currentBlock.points[i].index--;
            }
            // remove target point from this block
            currentBlock.points.splice(pointIndex, 1);
            
            // remove target point from tabs based on tab label
            for (var i = 0; i < tabNum; i++) {
                if (tabs[i].label === tab) {
                    let paramIndex = tabs[i].params.indexOf(paramId);
                    
                    if (paramIndex !== -1) {
                        this.modelForm.meta.tabs[i].params.splice(paramIndex, 1);
                    }
                }
            }
            
            this.$refs.paramTable.updatePoints();
            this.notify("Parameter : " + point.label + " has been removed!", "success");
        },

        editPoint(corePointInfo, pointIndex, blockIndex) {
            let point = this.modelForm.meta.blocks[blockIndex].points[pointIndex];

            // update point
            point.label = String(corePointInfo.label);
            point.dataType = Number(corePointInfo.dataType);
            point.slope = Number(corePointInfo.slope);
            point.postLabel = String(corePointInfo.postLabel);
            point.cloudEnabled = corePointInfo.cloudEnabled;
            point.units = String(corePointInfo.units);
            point.addressHex = String(corePointInfo.addressHex);
            point.address = Number(converter.hexToDec(corePointInfo.addressHex));

            /*
            * update tab if it changes
            * corePointInfo.tab is String "tab1/tab2"
            * corePointInfo.originalTab is String "tab1/tab2"
            */
            if (corePointInfo.tab !== corePointInfo.originalTab) {
                const newTabArray = corePointInfo.tab === "" ? [] : corePointInfo.tab.split('/');
                const originalTabArray = corePointInfo.originalTab === "" ? [] : corePointInfo.originalTab.split('/');

                let tabs = this.modelForm.meta.tabs
                let tabNum = tabs.length

                let tabsToRemove = [];
                let tabsToAdd = [];

                for (let tab of newTabArray) {
                    if (originalTabArray.indexOf(tab) === -1) {
                        tabsToAdd.push(tab);
                    }
                }

                for (let tab of originalTabArray) {
                    if (newTabArray.indexOf(tab) === -1) {
                        tabsToRemove.push(tab);
                    }
                }

                /*
                * remove the point from previous tabs 
                */
                if (tabsToRemove.length > 0) {
                    for (var i = 0; i < tabNum; i++) {
                        if (tabsToRemove.indexOf(tabs[i].label) !== -1) {
                            let paramIndex = tabs[i].params.indexOf(corePointInfo.paramId);

                            if (paramIndex !== -1) {
                                tabs[i].params.splice(paramIndex, 1);
                            } 
                        }
                    }
                }

                // add the point to new tabs
                if (tabsToAdd.length > 0) {
                    for (var i = 0; i < tabNum; i++) {
                        if (tabsToAdd.indexOf(tabs[i].label) !== -1) {
                            tabs[i].params.push(corePointInfo.paramId)
                        }
                    }
                }
            }

            this.notify("Parameter " + pointIndex + " has been updated!", "success");
        },

        deletePoint(pointIndex, tabLabels, blockIndex) {
            var currentBlock = this.modelForm.meta.blocks[blockIndex]
            const paramId = currentBlock.points[pointIndex].id;
            var pointNum = currentBlock.points.length

            // change all index of points after this point
            for (var i = pointIndex + 1; i < pointNum; i++) {
                currentBlock.points[i].index--
            }
            // remove target point from this block
            currentBlock.points.splice(pointIndex, 1)
            // remove target point from tabs based on tab label
            tabLabels = tabLabels.split('/')

            if (tabLabels.length !== 0) {
                var tabs = this.modelForm.meta.tabs
                var tabNum = tabs.length

                for (var i = 0; i < tabNum; i++) {
                    if (tabLabels.indexOf(tabs[i].label) !== -1) {
                        let paramIndex = tabs[i].params.indexOf(paramId);
                        
                        if (paramIndex !== -1) {
                            this.modelForm.meta.tabs[i].params.splice(paramIndex, 1);
                        }
                    }
                }
            }

            this.notify("Parameter " + pointIndex + " has been removed!", "success");
        },

        saveForm() {
            let modelForm = JSON.parse(JSON.stringify(this.modelForm));
            // ensure label and version exist
            if (modelForm.label === "") {
                this.notify("Device model is required");
                this.changeStep(0);
                return
            }

            if (modelForm.version === "") {
                this.notify("Device version is required");
                this.changeStep(0);
                return
            }

            if (modelForm.meta.classes[0].label === "") {
                this.notify("Cloud model is required");
                this.changeStep(0);
                return
            }

            // continue
            modelForm.last_updated_at = Math.floor(Date.now() / 1000)

            var data = {
                "name": modelForm.label,
                "version": modelForm.version,
                "cloudlabel": modelForm.meta.classes[0].label,
                "json": this.formatTemplate(modelForm)
            }

            this.request('post', '/api/template/modbus/', data)
            .then(response => {
                this.isSaved = true;

                this.$router.push({name: 'Homepage'});
                this.notify(response.data.message, "success");
            })
        },

        formatTemplate(modelForm) {
            // remove unnessary information
            modelForm.meta.blocks.forEach((block) => {
                delete block.startHex
                delete block.index
                delete block._showDetails
                
                block.points.forEach((point) => {
                    delete point.index
                    delete point.addressHex
                    delete point.block
                    delete point.unsaved
                    delete point.tab
                })
            })

            if (modelForm.meta.deviceInfo.length === 0) {
                delete modelForm.meta.deviceInfo;
            }
            else {
                // convert hex to decimal address
                modelForm.meta.deviceInfo.forEach(info => {
                    info.address = this.hexToDec(info.address);
                })
            }
            
            return modelForm
        },

        // resetForm() {
        //     this.modelForm = {
        //         uuid: this.generateUUID(),
        //         label: '',
        //         version: '',
        //         last_updated_at: '',
        //         meta: {
        //             tabs: [],
        //             blocks: [],
        //             classes: [{
        //                 label: '', // To be used in AcuCloud, it must be a model defined in AcuCloud, e.g., "Acuvim II", "AcuRev 2020-18 Channel", etc.
        //                 models: [ // suggest using one model only
        //                     {
        //                         label: '', // equals to modelForm.label
        //                         meta: {
        //                             protocols: {
        //                                 modbus: {
        //                                     tcp: true,
        //                                     rtu: true
        //                                 }
        //                             }
        //                         }
        //                     }
        //                 ]
        //             }]
        //         }
        //     };

        //     this.$refs.paramTable.resetPoints();
        //     this.initialModelForm = JSON.parse(JSON.stringify(this.modelForm));
        // },

        isConfigModified() {
            let modelForm = JSON.parse(JSON.stringify(this.modelForm));
            let initialModelForm = JSON.parse(JSON.stringify(this.initialModelForm));

            modelForm = this.formatTemplate(modelForm);
            initialModelForm = this.formatTemplate(initialModelForm);

            return JSON.stringify(initialModelForm) !== JSON.stringify(modelForm);
        }
    },

    beforeRouteLeave (to, from, next) {
        if (this.isConfigModified() && !this.isSaved) {
            const answer = window.confirm('Are you sure you want to exit? All locally saved configurations will be discarded.')
            if (answer) {
                next()
            } else {
                next(false)
            }
        }
        else {
            next()
        }
    },

    mounted: function() {
        var device = this.$route.params.device;
        var isNewModel = this.$route.params.isNewModel;

        this.isNewModel = isNewModel;

        if (device === 'new') {
            // this.selectDevice = 'create';
            this.isNewModel = true;

            if (this.$route.fullPath.includes('typical')) {
                this.modelForm.meta.classes[0].label = 'Typical Energy Meter';
                this.isTypicalEnergyMeter = true;
            }

            this.initialModelForm = JSON.parse(JSON.stringify(this.modelForm));
            this.isLoading = false;
        } else {
            var lastIndexOfDash = device.lastIndexOf("_");
            var model = device.slice(0, lastIndexOfDash);
            var version = device.slice(lastIndexOfDash + 1);

            // this.selectDevice = 'import';
            this.getExistingForm(model, version, isNewModel);
        }

        // Detech page leaving
        window.onbeforeunload = (e) => {
            if (this.isConfigModified() && !this.isSaved) {
                e = e || window.event;
                // IE8 and Firefox 4
                if (e) {
                    e.returnValue = 'Page Leave Confirmation';
                }
                // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
                return 'Page Leave Confirmation';
            }
        }
    },

    destroyed() {
        window.onbeforeunload = null;
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.spinner {
    width: 4rem;
    height: 4rem;
    position: fixed;
    top: 30%;
}

.button {
    margin-right: 2px;
    /*background-color: #3c81df !important;*/
}

.stepBtn {
    margin-right: 2px;
    margin-top: 10px;
    float: right;
}

#treeViewer {
    text-align: left;
    margin-bottom: 30px;
    overflow: auto;
}

.form-group {
    text-align: left !important;
}

.jumbotron {
    padding: 5px 5px 1px 10px;
    text-align: left;
}

.title {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
}

</style>