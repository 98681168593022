<template>
    <div id="typicalParamTable" v-if="points.length>0">
        <b-jumbotron>
            <p class="title">Parameter Table</p>
        </b-jumbotron>
        
        <div id="typicalParamTableContent">
            <!-- User Interface controls -->
            <b-row >
                <b-col md="4">
                    <b-form-group label-cols-sm="3" label="Filter" class="mb-0">
                        <b-input-group>
                            <b-form-input
                                v-model="filter"
                                placeholder="Type to Search"
                            />

                            <b-input-group-append>
                                <b-btn
                                    :disabled="!filter"
                                    @click="filter = ''"
                                >
                                    Clear
                                </b-btn>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group label-cols-sm="3" label="Sort" class="mb-0">
                        <b-input-group>
                            <b-form-select
                                v-model="sortBy"
                                :options="sortOptions"
                            >
                                <option slot="first" :value="null">-- none --</option>
                            </b-form-select>

                            <b-form-select
                                :disabled="!sortBy"
                                v-model="sortDesc"
                                slot="append"
                            >
                                <option :value="true">Desc</option>
                                <option :value="false">Asc</option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group label-cols-sm="3" label="Per page" class="mb-0">
                        <b-form-select
                            :options="pageOptions"
                            v-model="perPage"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <!-- Main table element -->
            <b-table
                id="typicalParamTable"
                ref="typicalParamTable"
                primary-key="param"
                hover
                small
                striped
                footClone
                show-empty
                stacked="md"
                :items="points"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :tbody-tr-class="rowClass"
                @filtered="onFiltered"
            >   
                <template slot="cloudEnabled" slot-scope="data">
                    {{formatBoolean(data.item.cloudEnabled)}}
                </template>

                <template slot="action" slot-scope="row">
                    <edit-typical-param-modal 
                        :point="row.item"
                        :paramAddressHex="row.item.addressHex"
                        :blocks="blocks"
                        :blockOptions="blockOptions"
                        @edit-typical-param="editTypicalParam(row.item.block, ...arguments)"
                    />
                    <delete-typical-param-modal
                        v-if="row.item.block"
                        :pointIndex="row.item.index"
                        :rowIndex="row.index"
                        :tab="row.item.tab"
                        :block="row.item.block"
                        @delete-typical-param="deleteTypicalParam"
                    />
                </template>
            </b-table>

            <b-pagination
                v-if="points.length>0"
                class="my-0"
                v-model="currentPage"
                :total-rows="points.length"
                :per-page="perPage"  
            />
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Vue from 'vue';
import EditTypicalParamModal from './editTypicalParamModal.vue';
import DeleteTypicalParamModal from './deleteTypicalParamModal.vue';
import { paramList } from '../fixtures/paramList';

var converter = require('hex2dec')

export default {
    name: 'ParamTable',
    props: ['blocks', 'blockOptions'],
    components: {
        'edit-typical-param-modal': EditTypicalParamModal,
        'delete-typical-param-modal': DeleteTypicalParamModal,
    },
    data() {
        return {
            currentPage: 1,
            perPage: 25,
            points: Object.values(paramList),
            radioOptions: [
                { text: 'True', value: 'true' },
                { text: 'False', value: 'false' }
            ],
            fields: [
                'label',
                'postLabel',
                
                {
                    key: 'block',
                    label: 'Block',
                    sortable: true,
                    sortDirection: 'desc'
                },
                'units',

                'addressHex',
                {
                    key: 'address',
                    label: 'Address',
                    sortable: true,
                    sortDirection: 'desc'
                },
                'dataType',
                'slope',
                
                'cloudEnabled',
                {
                    key: 'tab',
                    label: 'Tab',
                    sortable: true,
                    sortDirection: 'desc'
                },
                
                'action'
            ],
            pageOptions: [5, 10, 15, 25, 50, 100],
            filter: null,
            sortBy: 'block',
            sortDesc: true,
            sortDirection: 'desc',
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        },
    },
    watch: {},
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        rowClass(item, type) {
            if (item && item.unsaved) {
                return 'table-success';
            }

            return '';
        },

        editTypicalParam(block, param) {
            this.$emit('edit-typical-param', param, block);
        },

        deleteTypicalParam(pointIndex, tab, block, rowIndex) {
           
            this.$emit('delete-typical-param', pointIndex, tab, block);

            // const initialParam = paramList[this.points[rowIndex].label];
            // Vue.set(this.points, rowIndex, initialParam);
        },

        formatBoolean(value) {
            if (value === true) {
                return 'True';
            }
            else if (value === false) {
                return 'False';
            }
            else {
                return value;
            }
        },

        updatePoints() {
            let params = JSON.parse(JSON.stringify(paramList));
            let paramLabel = '';
            let blockLabel = '';

            this.blocks.forEach((block, index) => {
                blockLabel = this.generateBlockLabel(block.start, block.count, index);
                block.points.forEach(point => {
                    paramLabel = point.label;

                    if (paramList.hasOwnProperty(paramLabel)) {
                        // add new fields
                        point.block = blockLabel;
                        point.tab = paramList[paramLabel].tab;
                        // update default parameter list
                        params[paramLabel] = point;
                    }
                })
            })

            this.points = Object.values(params);
        },

        resetPoints() {
            let params = JSON.parse(JSON.stringify(paramList));
            this.points = Object.values(params);
        }
    },

    mounted: function() {
        this.updatePoints();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.jumbotron {
    padding: 5px 5px 1px 10px;
    text-align: left;
}

.title {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
}

#typicalParamTableContent {
    width: 90%;
    margin: 20px auto;
}

</style>