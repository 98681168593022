export const byteOrderOptions = [
    {
        text: 'NORMAL',
        value: 0
    },
    {
        text: 'BA',
        value: 1
    },
    {
        text: 'DCBA',
        value: 2
    },
    {
        text: 'CDAB',
        value: 3
    },
    {
        text: 'BADC',
        value: 4
    },
    {
        text: 'GHEFCDAB',
        value: 5
    },
];

export const dataFormatOptions = [
    {
        text: 'UINT16',
        value: 0
    },
    {
        text: 'UINT64',
        value: 13
    },
    {
        text: 'INT16',
        value: 1
    },
    {
        text: 'INT64',
        value: 14
    },
    {
        text: 'UINT32',
        value: 2
    },
    {
        text: 'INT32',
        value: 3
    },
    {
        text: 'FLOAT',
        value: 4
    },
    {
        text: 'MOD10K_4',
        value: 5
    },
    {
        text: 'MOD10K_3',
        value: 6
    },
    {
        text: 'MOD10K_2',
        value: 7
    },
    {
        text: 'MOD1K_4',
        value: 8
    },
    {
        text: 'MOD1K_3',
        value: 9
    },
    {
        text: 'MOD1K_2',
        value: 10
    },
    {
        text: 'HEX16',
        value: 11
    },
];

export const regByteOrderOptions = [
    {
        text: 'NORMAL',
        value: 0
    },
    {
        text: 'BA',
        value: 1
    },
    {
        text: 'DCBA',
        value: 2
    },
    {
        text: 'CDAB',
        value: 3
    },
    {
        text: 'BADC',
        value: 4
    },
    {
        text: 'GHEFCDAB',
        value: 5
    },
    {
        text: 'STRING',
        value: 250
    },
];

export const regDataFormatOptions = [
    {
        text: 'UINT16',
        value: 0
    },
    {
        text: 'INT16',
        value: 1
    },
    {
        text: 'UINT32',
        value: 2
    },
    {
        text: 'INT32',
        value: 3
    },
    {
        text: 'FLOAT',
        value: 4
    },
    {
        text: 'MOD10K_4',
        value: 5
    },
    {
        text: 'MOD10K_3',
        value: 6
    },
    {
        text: 'MOD10K_2',
        value: 7
    },
    {
        text: 'MOD1K_4',
        value: 8
    },
    {
        text: 'MOD1K_3',
        value: 9
    },
    {
        text: 'MOD1K_2',
        value: 10
    },
    {
        text: 'HEX16',
        value: 11
    },
    {
        text: 'STRING',
        value: 250
    },
];

export const officialOptions =[
    {
        text: 'True',
        value: true,
    },
    {
        text: 'False',
        value: false,
    },
];

export const cloudEnabledOptions = [
    {
        text: 'True',
        value: true
    },
    {
        text: 'False',
        value: false
    },
];

export const blockFunctions = [
    'READ_HOLDING_REGISTERS',
    'READ_COILS',
    'READ_DISCRETE_INPUTS',
    'READ_INPUT_REGISTERS',
    'WRITE_MULTIPLE_REGISTERS',
    'WRITE_MULTIPLE_COILS',
];

export const unitOptions = [
    {
        group: 'Energy',
        libs: [
            "kWh",
            "Wh",
            "MWh",
            "VAh",
            "kVAh",
            "MVAh",
            "VARh",
            "kVARh",
            "kvarh",
            "MVARh",
            "Btu",
            "kBtu",
            "MBtu",
            "MMBtu",
            "BtuE6",
            "Ton-Hrs",
            "therms",
            "Ah",
        ]
    },
    {
        group: 'Power',
        libs: [
            "W",
            "Watts",
            "mW",
            "milliwatts",
            "kW",
            "MW",
            "VA",
            "kVA",
            "MVA",
            "VAR",
            "kVAR",
            "kvar",
            "MVAR",
            "Btu/hr",
        ]
    },
    {
        group: 'Voltage',
        libs: [
            "Volts",
            "V",
            "mV",
            "millivolts",
            "kV",
            "MV",
        ]
    },
    {
        group: 'Current',
        libs: [
            "Amps",
            "A",
            "mA",
            "milliamps",
        ]
    },
    {
        group: 'Event Counting',
        libs: [
            "cycles",
            "pulses",
            "revolutions",
            "starts",
        ]
    },
    {
        group: 'Frequency',
        libs: [
            "Hz",
            "kHz",
            "RPM",
        ]
    },
    {
        group: 'Resistance',
        libs: [
            "Ohms",
            "kohms",
        ]
    },
    {
        group: 'Mass',
        libs: [
            "kgs",
            "Lbs",
            "Tons",
        ]
    },
    {
        group: 'Mass Flow',
        libs: [
            "kg/hr",
            "Lb/hr",
        ]
    },
    {
        group: 'Volume',
        libs: [
            "Gallons",
            "Cubic Feet",
            "Cubic Meters",
            "Liters",
        ]
    },
    {
        group: 'Volume Flow',
        libs: [
            "Cubic Feet/sec",
            "Cubic Feet/min",
            "CFm",
            "Cubic Feet/min",
            "Cubic Feet/hr",
            "CFH",
            "Cubic Feet/hr",
            "Cubic Meters/hr",
            "Gpm",
            "Gallons per minute",
            "Gph",
            "Gallons per hour",
            "MGD",
            "millions of gallons per day",
            "Liters/sec",
            "Liters/min",
            "Liters/hr",
        ]
    },
    
    {
        group: 'Time',
        libs: [
            "days",
            "hours",
            "minutes",
            "min",
            "minutes",
            "seconds",
            "sec",
            "seconds",
            "ms",
            "milliseconds",
        ]
    },
    {
        group: 'Pressure',
        libs: [
            "Pa",
            "Pascals",
            "kPa",
            "kilopascals",
            "inWC",
            "inches water column",
            "inAq",
            "inches Aqua",
            "inHg",
            "inches Mercury",
            "cmHg",
            "centimeters Mercury",
            "mmHg",
            "millimeters Mercury",
        ]
    },
    {
        group: 'Velocity',
        libs: [
            "MPH",
            "miles per hour",
            "KPH",
            "kilometers per hour",
        ]
    },
    {
        group: 'Dimensionless',
        libs: [
            "%",
            "PPM",
            "parts per million",
            " ",
        ]
    },
    {
        group: 'Phase',
        libs: [
            "Degrees",
        ]
    },
    {
        group: 'Electrical',
        libs: [
            "PF",
            "aPF",
            "dPF",
        ]
    },
    {
        group: 'Intensity',
        libs: [
            "W/m^2",
        ]
    },
    {
        group: 'Temperature',
        libs: [
            "Degrees C",
            "Degrees F",
            "°",
        ]
    },
    {
        group: 'Humidity',
        libs: [
            "%RH",
        ]
    },
];

const postLabels = [
    'DMD_P_kW',
    'DMD_Pa_kW',
    'DMD_Pb_kW',
    'DMD_Pc_kW',
    'DMD_Q_kvar',
    'DMD_Qa_kvar',
    'DMD_Qb_kvar',
    'DMD_Qc_kvar',
    'DMD_S_kVA',
    'DMD_Sa_kVA',
    'DMD_Sb_kVA',
    'DMD_Sc_kVA',
    'EP_EXP_kWh',
    'EP_IMP_kWh',
    'EPa_EXP_kWh',
    'EPa_IMP_kWh',
    'EPb_EXP_kWh',
    'EPb_IMP_kWh',
    'EPc_EXP_kWh',
    'EPc_IMP_kWh',
    'EQ_EXP_kvarh',
    'EQ_IMP_kvarh',
    'EQa_EXP_kvarh',
    'EQa_IMP_kvarh',
    'EQb_EXP_kvarh',
    'EQb_IMP_kvarh',
    'EQc_EXP_kvarh',
    'EQc_IMP_kvarh',
    'ES_kVAh',
    'ESa_kVAh',
    'ESb_kVAh',
    'ESc_kVAh',
    'Freq_Hz',
    'Ia_A',
    'Iavg_A',
    'Ib_A',
    'Ic_A',
    'PF',
    'PFa',
    'PFb',
    'PFc',
    'P_kW',
    'Pa_kW',
    'Pb_kW',
    'Pc_kW',
    'Q_kvar',
    'Qa_kvar',
    'Qb_kvar',
    'Qc_kvar',
    'S_kVA',
    'Sa_kVA',
    'Sb_kVA',
    'Sc_kVA',
    'Va_V',
    'Vab_V',
    'Vb_V',
    'Vbc_V',
];

export const postLabelOptions = postLabels.map(label => {
    return {
        text: label,
        value: label
    }
});
