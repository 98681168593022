<template>
    <span id="BACnetPointModal">
        <b-modal
            ref="BACnetPointModal"
            title="Select points to create template"
            @ok="filterSelectedPoints"
            size="xl"
            centered  
        >
            <b-container id="tableContent">
                <!-- User Interface controls -->
                <b-row >
                    <b-col md="4">
                        <b-form-group label-cols-sm="3" label="Filter" class="mb-0">
                            <b-input-group>
                                <b-form-input
                                    v-model="filter"
                                    placeholder="Type to Search"
                                />

                                <b-input-group-append>
                                    <b-btn
                                        :disabled="!filter"
                                        @click="filter = ''"
                                    >
                                        Clear
                                    </b-btn>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label-cols-sm="3" label="Sort" class="mb-0">
                            <b-input-group>
                                <b-form-select
                                    v-model="sortBy"
                                    :options="sortOptions"
                                >
                                    <option slot="first" :value="null">-- none --</option>
                                </b-form-select>

                                <b-form-select
                                    :disabled="!sortBy"
                                    v-model="sortDesc"
                                    slot="append"
                                >
                                    <option :value="true">Desc</option>
                                    <option :value="false">Asc</option>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label-cols-sm="3" label="Per page" class="mb-0">
                            <b-form-select
                                :options="pageOptions"
                                v-model="perPage"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                
                <b-form-checkbox
                    v-model="allSelected"
                    @change="toggleAll"
                >
                    {{ allSelected ? 'Un-select All' : 'Select All' }}
                </b-form-checkbox>

                <!-- Main table element -->
                <b-table
                    id="typicalParamTable"
                    ref="typicalParamTable"
                    hover
                    small
                    striped
                    footClone
                    show-empty
                    stacked="md"
                    selectable
                    select-mode="single"
                    selectedVariant=""
                    :items="formatedPoints"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :tbody-tr-class="rowClass"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >   
                    <template slot="isSelected" slot-scope="data">
                        <span v-if="data.item.isSelected">✔</span>
                    </template>
                </b-table>

                <b-pagination
                    v-if="formatedPoints.length>0"
                    class="my-0"
                    v-model="currentPage"
                    :total-rows="formatedPoints.length"
                    :per-page="perPage"  
                />
            </b-container>
        </b-modal>
    </span>
</template>

<script>
// import paramFixtureOptions from '../fixtures/paramOptions';
export default {
    name: 'BACnetPointModal',
    props: [],
    data() {
        return {
            allSelected: false,

            currentPage: 1,
            perPage: 25,
            formatedPoints: [],

            fields: [
                {
                    key: 'isSelected',
                    label: 'Selected',
                    sortable: false,
                    sortDirection: 'asc'
                },

                'label',
                {
                    key: 'objectId',
                    label: 'Object Id',
                    sortable: true,
                    sortDirection: 'asc'
                },

                {
                    key: 'objectType',
                    label: 'Object Type',
                    sortable: true,
                    sortDirection: 'asc'
                },
            ],
            pageOptions: [5, 10, 15, 25, 50, 100],
            filter: null,
            sortBy: 'objectType',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        },
    },
    watch: {
        selected(newVal) {
            // Handle changes in individual flavour checkboxes
            if (newVal.length === 0) {
              this.allSelected = false
            } else if (newVal.length === this.formatedPoints.length) {
              this.allSelected = true
            } else {
              this.allSelected = false
            }
        }
    },
    methods: {
        initializeModal(points) {
            let formatedPoints = [];

            points.forEach((point, index) => {
                formatedPoints.push({
                    pointIndex: index,
                    isSelected: false,
                    label: point.label,
                    objectId: point.objectId,
                    objectType: point.objectType
                });
            })

            this.formatedPoints = formatedPoints;
            this.$refs.BACnetPointModal.show()
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        rowSelected(items) {
            if (items.length === 1) {
                const pointIndex = items[0].pointIndex;

                this.formatedPoints[pointIndex].isSelected = !this.formatedPoints[pointIndex].isSelected;
            }   
        },

        rowClass(item) {
            if (item && item.isSelected) {
                return 'table-success';
            }

            return '';
        },

        toggleAll(checked) {
            this.formatedPoints.forEach(point => point.isSelected = checked);
        },

        reset() {
            this.allSelected = false;
            this.currentPage = 1;
            this.perPage = 25;
            this.formatedPoints = [];
            this.filter = null;
            this.sortBy = 'objectType';
            this.sortDesc = false;
            this.sortDirection = 'asc';
        },

        filterSelectedPoints() {
            const selectedPoints = this.formatedPoints.filter(point => point.isSelected);
            const selectedPointsIndex = selectedPoints.map(point => point.pointIndex);

            this.$emit('filter-selected-points', selectedPointsIndex);
            this.reset();
        }
            
    },
    mounted() {
    }
}
</script>

<style scoped>
#BACnetPointModal {
    display: inline-block;
    margin-left: 2px;
}

#tableContent {
    width: 80%;
    min-width: 600px;
}

</style>